import {SiteBannerDto} from '@/features/archiveAsAProduct/types/dto/banner';
import {SiteBanner} from '@/features/archiveAsAProduct/types/banner';
import {DateTime} from 'luxon';

/**
 * Parses a date string in dd/mm/yyyy hh:mm am/pm format to a Date in EST.
 * Assumes EST is always at UTC-05:00.
 */
function parseESTDate(dateStr: string): Date {
  // Use the format "dd/MM/yyyy h:mm a" to match strings like "15/03/2025 2:40 pm"
  // The provided zone ensures the time is parsed in America/New_York time.
  const dt = DateTime.fromFormat(dateStr, 'dd/MM/yyyy h:mm a', {
    zone: 'America/New_York',
  });
  return dt.toJSDate();
}

/**
 * Transforms a SiteBannerDto into a local SiteBanner object.
 * Returns undefined if the current archive isn’t included in the banner_sites.
 */
export function transformSiteBanner(
  dto: SiteBannerDto,
  archive: string
): SiteBanner | undefined {
  const bannerData = dto.banner;
  if (!bannerData || !Array.isArray(bannerData.banner_sites)) return undefined;

  // Convert both the archive and banner sites to lower case for comparison.
  const archiveLower = archive.toLowerCase();
  const bannerSitesLower = bannerData.banner_sites.map(site =>
    site.toLowerCase()
  );
  if (!bannerSitesLower.includes(archiveLower)) return undefined;

  const start = parseESTDate(bannerData.banner_start);
  const end = parseESTDate(bannerData.banner_end);

  return {
    color: bannerData.banner_color,
    headerText: bannerData.banner_header_text,
    content: bannerData.banner_content,
    start,
    end,
  };
}
