import {zodParseToResult} from '@/features/archiveAsAProduct/lib/zod';
import {Result, err, ok} from '@/lib/utils/result';
import {ZodSchema, z} from 'zod';
import {ValidationRule} from '.';
import {getRequestedRuleData} from '../parse';
import {isObject} from '@/lib/utils/validation';
import {getObjectArguments} from '../arguments';

export type ObjectChildRules<Output> = {
  [P in keyof Output]: ValidationRule<Output[P]>;
};

type ObjectZodSchema<Output> = Record<
  keyof Output,
  ZodSchema<Output[keyof Output]>
>;

/** A rule for validating an object containing one or more properties. */
export class ObjectValidationRule<
  Output,
  Input = Output
> extends ValidationRule<Output, Input> {
  _content: ObjectChildRules<Output>;
  _schema: ZodSchema;

  constructor(property: string, content: ObjectChildRules<Output>);
  constructor(content: ObjectChildRules<Output>);
  constructor(
    arg1: string | ObjectChildRules<Output>,
    arg2?: ObjectChildRules<Output>
  ) {
    const {property, content} = getObjectArguments(arg1, arg2);

    super(property);
    if (isObject(content)) this._content = content;
    else this._content = {} as ObjectChildRules<Output>;

    const schema = buildObjectSchema<Input>(this._content);
    this._schema = schema;
  }

  _safeParse(data: unknown): Result<Output> {
    const targetData = getRequestedRuleData(data, this._property);
    if (!isObject(targetData)) {
      const zodResult = this._schema.safeParse(targetData);
      return zodParseToResult(zodResult);
    }

    if (!isObject(this._content)) {
      return err('Content for object validation is invalid');
    }

    const contentEntries: [string, ValidationRule<unknown>][] = Object.entries(
      this._content
    );
    const parsedData: Partial<Output> = {};
    const errors: string[] = [];

    for (const [key, validationRule] of contentEntries) {
      const result = validationRule.safeParse(targetData);
      if (result.ok !== true) {
        errors.push(result.error);
        continue;
      }

      parsedData[key] = result.data;
    }
    if (errors.length) return err(errors.join('. '));
    return ok(parsedData as Output);
  }
}

/**
 * Builds a Zod Object schema for validation
 * @param content - The validation rules
 * @returns A ZodObject schema
 */
function buildObjectSchema<Input>(
  content: Record<string, ValidationRule<unknown>>
) {
  const schema = {};
  if (!isObject(content)) return z.object(schema as ObjectZodSchema<Input>);

  const entries = Object.entries(content);
  entries.forEach(([key, rule]) => (schema[key] = rule.getZod()));

  return z.object(schema);
}
