import {HttpResponse} from '@/lib/types/http';
import http from '@/lib/api/data-explore/httpCore';
import {getWordpressHeaders} from '@/features/archiveAsAProduct/utils/api/wordpress';
import {SiteBannerDto} from '@/features/archiveAsAProduct/types/dto/banner';

export async function getSiteBanners(): Promise<HttpResponse<SiteBannerDto[]>> {
  const headers = getWordpressHeaders();
  const url = '/shared/wp-json/custom/v1/site-banners';
  return http.get(url, headers);
}
