/**
 * A standard Result object containing the success (ok) of a function,
 * its data (on success) or the reason why it failed (on failure).
 */
export type Result<T, U = string> = OkResult<T> | ErrResult<U>;
export class OkResult<T> {
  ok = true as const;
  data: T;

  constructor(data: T) {
    this.data = data;
  }

  unwrap(): T {
    return this.data;
  }
}

export class ErrResult<U = string> {
  ok = false as const;
  error: U;

  constructor(error: U) {
    this.error = error;
  }

  unwrap(): never {
    if (typeof this.error === 'string') throw this.error;
    throw this.error;
  }
}

/**
 * Encapsulates data within a successful response
 * @param data - The data to encapsulate within the successful result
 * @returns A successful Result object
 */
export function ok<T>(data: T): Result<T, never> {
  return new OkResult<T>(data);
}

/**
 * Encapsulates the reason for an error within an unsuccessful response
 * @param error - The error to encapsulate
 * @returns An unsuccessful Result object
 */
export function err<U>(error: U): Result<never, U> {
  return new ErrResult<U>(error);
}
