import {Config} from 'isomorphic-dompurify';

export const WordpressSanitizationConfig: Config = {
  FORBID_TAGS: ['style'],
  FORBID_ATTR: ['style'],
  ADD_ATTR: [
    'abbr',
    'alt',
    'aria-controls',
    'aria-expanded',
    'aria-label',
    'aria-labelledby',
    'aria-multiselectable',
    'axis',
    'border',
    'cellpadding',
    'cellspacing',
    'class',
    'colspan',
    'data-bs-parent',
    'data-bs-target',
    'data-bs-toggle',
    'data-parent',
    'data-toggle',
    'for',
    'frameborder',
    'headers',
    'height',
    'href',
    'hspace',
    'id',
    'lang',
    'name',
    'noresize',
    'nowrap',
    'role',
    'rowspan',
    'scope',
    'size',
    'span',
    'src',
    'style',
    'tabindex',
    'target',
    'title',
    'type',
    'vspace',
    'width',
  ],
  ADD_TAGS: [
    'a',
    'article',
    'aside',
    'b',
    'big',
    'blockquote',
    'br',
    'button',
    'caption',
    'center',
    'cite',
    'code',
    'col',
    'colgroup',
    'dd',
    'div',
    'dl',
    'dt',
    'em',
    'fieldset',
    'footer',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'header',
    'hr',
    'i',
    'iframe',
    'img',
    'label',
    'legend',
    'li',
    'main',
    'map',
    'nav',
    'noscript',
    'ol',
    'p',
    'pre',
    'samp',
    'section',
    'small',
    'span',
    'strike',
    'strong',
    'sub',
    'sup',
    'table',
    'tbody',
    'td',
    'tfoot',
    'th',
    'thead',
    'tr',
    'u',
    'ul',
  ],
  // Regex is built from the ICPSR-Common-Web HtmlSanitizerPolicy.java file, with additions from DomPurify's defaults
  ALLOWED_URI_REGEXP:
    /^(?:(?:(?:ht|f)tps?:\/\/|mailto:)[\w\d][\w\d .#@$%+&;:\-_~,?=/!()]+|#[a-zA-Z0-9:\-_.]*|[^a-z]|[a-z+.-]+(?:[^a-z+.\-:]|$))/,
};
