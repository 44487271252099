import {OkResult} from '@/lib/utils/result';
import {v} from '../validation';

export type SidebarContent = {
  acfFcLayout: string;
  primaryNavigationItem: string;
  hierarchal: boolean;
  externalLink: boolean;
  externalLinkUrl: string;
  primaryPageSlug: string | null;
  nestedNavigationItems: false | NestedSidebarContent[];
};

type NestedSidebarContent = {
  pageTitle: string;
  externalLink: boolean;
  externalLinkUrl: string;
  pageSlug: string | null;
};

const NESTED_SIDEBAR_CONTENT_VALIDATION = v.object<NestedSidebarContent>({
  pageTitle: v.string('page_title'),
  externalLink: v.boolean('external_link'),
  externalLinkUrl: v.string('external_link_url'),
  pageSlug: v.string('page_slug'),
});

const SIDEBAR_CONTENT_VALIDATION = v.array(
  'local_navigation_items',
  v.object<SidebarContent>({
    acfFcLayout: v.string('acf_fc_layout'),
    primaryNavigationItem: v.string('primary_navigation_item'),
    hierarchal: v.boolean('hierarchal').default(false),
    externalLink: v.boolean('external_link'),
    externalLinkUrl: v.string('external_link_url'),
    primaryPageSlug: v.string('primary_page_slug').optional(),
    nestedNavigationItems: v
      .array<NestedSidebarContent>(
        'nested_navigation_items',
        NESTED_SIDEBAR_CONTENT_VALIDATION
      )
      .optional(),
  })
);

export function transformSidebar(dto) {
  const result = SIDEBAR_CONTENT_VALIDATION.safeParse(dto.data);
  return result;
}
