import {ImageMedia} from '../types/media';

/**
 * Determines if a given value is a non-array object (eg, something that would match `Record<string, unknown>`)
 * @param possibleObject - Some value that may be an object
 * @returns True if an object; false otherwise
 */
export function isObject<T extends object>(
  possibleObject: unknown
): possibleObject is T {
  if (!possibleObject) return false; // Explicity for the null case because typeof null is 'object'???
  if (typeof possibleObject !== 'object') return false;
  if (Array.isArray(possibleObject)) return false;
  return true;
}

/**
 * Checks if the given object is an ImageMedia object
 * @param image - The image object to validate
 * @returns True if a valid ImageMedia object, false otherwise.
 */
export function isImageMedia(image: ImageMedia) {
  if (!isObject(image)) return false;
  if (image.type !== 'image' || !image.src || !image.alt) return false;
  return true;
}

/**
 * Checks if the given value is a string containin non-whitespace characters
 * @param possibleString - The possible string to validate
 * @returns True if a non-empty string, false otherwise
 */
export function isNonEmptyString(
  possibleString: unknown
): possibleString is string {
  if (typeof possibleString !== 'string') return false;
  return possibleString.trim().length > 0;
}
