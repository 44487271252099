import {UserDTO, WordpressUser} from '../../types/user';
import {v} from '../validation';
import {Result} from '@/lib/utils/result';

const USER_VALIDATION = v.object<WordpressUser, UserDTO>({
  id: v.number('id'),
  firstName: v.string('first_name'),
  lastName: v.string('last_name'),
});

export function transformUser(dto: UserDTO): Result<WordpressUser> {
  return USER_VALIDATION.safeParse(dto);
}
