import {FeaturedResourceDto} from '../../types/dto/resource';
import {FeaturedResource, FeaturedResourceCategory} from '../../types/resource';
import {v} from '../validation';
import {ArrayValidationRule} from '../validation/rules/array';

const FEATURED_RESOURCE_VALIDATION = v.object<
  FeaturedResource,
  FeaturedResourceDto
>({
  id: v.string<number>('ID').transform(id => +id),
  title: v.string('title').required(),
  content: v.string('featured_content').required(),
  category: v
    .string<FeaturedResourceCategory>('category')
    .default('Internal resources'),
});

const FEATURED_RESOURCE_ARRAY_VALIDATION: ArrayValidationRule<FeaturedResource> =
  v.array<FeaturedResource>(FEATURED_RESOURCE_VALIDATION);

export function transformFeaturedResource(
  featuredResourceDto: FeaturedResourceDto
): FeaturedResource | undefined {
  const featuredResource =
    FEATURED_RESOURCE_VALIDATION.safeParse(featuredResourceDto);

  if (featuredResource.ok === false) {
    return undefined;
  }
  return featuredResource.data;
}

export function transformFeaturedResources(
  featuredResourceDtos: FeaturedResourceDto[]
): FeaturedResource[] {
  const featuredResources =
    FEATURED_RESOURCE_ARRAY_VALIDATION.safeParse(featuredResourceDtos);

  if (featuredResources.ok === false) {
    return [];
  }
  return featuredResources.data ?? [];
}
