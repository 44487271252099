import React from 'react';
import {IcpsrLoginDropdown} from '@icpsr/auth/lib/esm';
import getConfig from 'next/config';
import {signIn, signOut, useSession} from 'next-auth/react';
import {useRouter} from 'next/router';

export function SignInUser() {
  const config = getConfig();
  const router = useRouter();
  const {data: session} = useSession();
  return (
    <div className="nav-item bg-secondary" data-testid="icpsr-login">
      <IcpsrLoginDropdown
        signIn={signIn}
        signOut={signOut}
        session={session}
        providerId={config.publicRuntimeConfig.nextAuthKeycloakProviderId}
        currentPath={router.asPath}
        authUiPath={config.publicRuntimeConfig.authUiHost}
        myDashboardPath={config.publicRuntimeConfig.myDashboardPath}
        referrer={router.basePath + router.asPath}
      />
    </div>
  );
}
