import {Result} from '@/lib/utils/result';
import {ZodNumber, z} from 'zod';
import {ValidationRule} from '.';
import {parseScalar} from '../parse';
import {LiteralValidationRule} from './literal';

/** A rule for validating and transforming a number value */
export class NumberValidationRule extends ValidationRule<number> {
  _schema: ZodNumber;
  _default: 0;

  constructor(property: string) {
    super(property);
    this._schema = z.number();
  }

  /**
   * Adds a rule requiring that the validated value must equal the given value
   * Equivalent to `.literal(property?, value)`
   * @param value - The value to validate with
   */
  equals(value: number) {
    const literalRule = new LiteralValidationRule<number>(
      this._property,
      value
    );
    this._replaceWith(literalRule);
    return literalRule;
  }

  _safeParse(data: unknown): Result<number> {
    return parseScalar(data, this._property, this._schema);
  }
}
