import {err, ok, Result} from '@/lib/utils/result';
import {isNonEmptyString} from '@/lib/utils/validation';
import {ZodSchema} from 'zod';
import {ValidationRuleFactory} from '../factory';

/** The core functionality for all Validation rules */
export abstract class ValidationRule<Output, Input = Output> {
  /** The Zod schema performing any validation */
  _schema: ZodSchema<unknown>;
  /** The key of the property containing the data to validate. If undefined, the base value is evaluated instead */
  _property?: string;

  /** Marks this field as required, returning an error if invalid */
  _required?: boolean;
  /** Marks this field as optional, returning undefined if invalid */
  _optional?: boolean;
  /** The default value to return if invalid if neither required or optional are set */
  _default: unknown;
  /** A custom transformation function */
  _transform: (data: unknown) => Output;

  /** The first validation rule in this rule chain */
  _parent: ValidationRule<Output, unknown>;
  /** The validation rule after this one in the rule chain */
  _next?: ValidationRule<Output, unknown>;

  constructor(property: string | undefined) {
    if (isNonEmptyString(property)) this._property = property;
    this._parent = this;
  }

  /**
   * Gets the Zod schema for the current validation rule
   */
  getZod(): ZodSchema<Output> {
    return this._schema as ZodSchema<Output>;
  }

  /**
   * Sets a default value
   * @param value - The value to use as a default value
   */
  default(value: Output) {
    this._default = value;
    return this;
  }

  /**
   * Sets the top-level parent of the current rule
   * @param rule - The ValidationRule to make the top-level parent of this rule
   */
  _setParent(rule: ValidationRule<Output>) {
    this._parent = rule;
    return this;
  }

  /**
   * Sets the next rule of the current rule
   * @param rule - The rule to set as the next rule after this one
   */
  _setNext(rule: ValidationRule<Output>) {
    this._next = rule;
    return this;
  }

  /**
   * Replaces the current rule, updating the parent and rule chain appropriately.
   * @param rule - The new rule to replace the current rule with
   */
  _replaceWith(rule: ValidationRule<Output>) {
    rule._parent = this._parent;

    let currentRule = this._parent._next;
    let previousRule = this._parent;
    while (currentRule !== undefined) {
      const currentRuleIsRuleToReplace = currentRule === this;
      if (currentRuleIsRuleToReplace) {
        previousRule._next = rule;
        rule._next = currentRule._next;
      }
      previousRule = currentRule;
      currentRule = currentRule._next;
    }

    const thisIsParent = this._parent === this;
    if (thisIsParent) {
      rule._parent = rule;
      rule._next = this._next;
    }
    return rule;
  }

  /**
   * Marks this entire field as optional, forcing it to return an undefined value if not found
   */
  optional() {
    this._parent._optional = true;
    return this;
  }

  /**
   * Starts a new rule
   * @returns An entrypoint for a new ValidationRule
   */
  or(): ValidationRuleFactory {
    const chainedEntry = new ValidationRuleFactory(this);
    return chainedEntry;
  }

  /**
   * Parses the data, throwing an error on failure
   * @param data - The data to parse
   * @throws On parse failure
   * @returns The validated and parsed data
   */
  parse(data: unknown): Output {
    const result = this.safeParse(data);
    if (result.ok === false) throw result.error;
    return result.data;
  }

  /**
   * Marks this field as required.
   */
  required() {
    this._parent._required = true;
    return this;
  }

  /**
   * Safely parses the data, returning the data in a result.
   * @param data - The data to parse
   * @returns A Result containing the validated output, or an error
   */
  safeParse(data: unknown): Result<Output> {
    let current: ValidationRule<Output, unknown> | undefined = this._parent;
    const errors: string[] = [];
    const maxLoops = 10;
    let loop = 0;

    while (current !== undefined) {
      if (loop > maxLoops) {
        return err(
          `The maximum safe parse loop of ${maxLoops} has been reached.`
        );
      }
      loop++;

      const result = current._safeParse(data);
      current = current._next;
      if (result.ok === false) {
        errors.push(result.error);
        continue;
      }

      const transformResult = this._tryTransform(result.data);
      if (transformResult.ok === true) return transformResult;
      errors.push(transformResult.error);
    }

    if (this._parent._required) return err(errors.join('. '));
    if (this._parent._optional) return ok(undefined);
    return ok(this._default as Output);
  }

  /**
   * Set a custom function to transform the data
   * @param transformFunction - The function to transform the data
   */
  transform(transformFunction: (data: unknown) => Output) {
    if (typeof transformFunction !== 'function') return this;
    this._transform = transformFunction;
    return this;
  }

  /**
   * Attempts to transform the provided data
   * @param data - The data to transform
   * @returns A Result containing the transformed data, or an error
   */
  _tryTransform(data: unknown): Result<Output> {
    if (!this._transform) return ok(data as Output);
    try {
      return ok(this._transform(data as Input));
    } catch (e) {
      return err(e);
    }
  }

  abstract _safeParse(data: unknown): Result<Output>;
}
