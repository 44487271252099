import {WordpressStaffDto} from '@/features/archiveAsAProduct/types/dto/staff';
import {
  WordpressStaff,
  WordpressStaffMemberPage,
} from '@/features/archiveAsAProduct/types/staff';
import {transformWordpressMedia} from './media';
import {isObject} from '@/lib/utils/validation';
import {transformWordpressSeo} from './seo';
import {v} from '../validation';
import {WordpressPageDto} from '../../types/dto/page';
import {Result} from '@/lib/utils/result';

/**
 * Transforms a staff member data transfer object into a standard WordpressStaff object
 * @param dto - The data transfer object describing a staff member
 * @returns A WordpressStaff object
 */
export function transformWordpressStaff(
  dto: WordpressStaffDto
): WordpressStaff {
  if (!isObject(dto)) dto = {} as WordpressStaffDto;

  const acf = transformWordpressStaffAcf(dto.acf);
  const seo = transformWordpressSeo(dto.acf?.seo);
  const staff: WordpressStaff = {
    id: dto.id ?? 0,
    type: 'staff_member',
    template: dto.template ?? '',
    seo,
    acf,
  };
  return staff;
}

/**
 * Transforms a staff member data transfer object's advanced custom fields into
 * a standard WordpressStaff's ACF object
 * @param dto - The advanced custom field section of the data transfer object
 *  describing a staff member
 * @returns A WordpressStaff's advanced custom field object
 */
function transformWordpressStaffAcf(dto: Partial<WordpressStaffDto['acf']>) {
  if (!isObject(dto)) dto = {};

  const bioPicture = transformWordpressMedia(dto.bio_picture);

  const acf: WordpressStaff['acf'] = {
    firstName: dto.first_name ?? '',
    lastName: dto.last_name ?? '',
    bioPicture,
    title: {
      long: dto.title_long ?? '',
      short: dto.title_short ?? '',
    },
    biography: dto.biography ?? '',
  };
  return acf;
}

const STAFF_MEMBER_PAGE_VALIDATION = v.object<
  WordpressStaffMemberPage,
  WordpressPageDto
>({
  id: v.number('id').required(),
  slug: v.string('slug').required(),
  firstName: v.string('acf.first_name').required(),
  lastName: v.string('acf.last_name').required(),
  titleLong: v.string('acf.title_long'),
  titleShort: v.string('acf.title_short'),
  biography: v.string('acf.biography'),
});

export function transformStaffMemberPage(
  dto: WordpressPageDto
): Result<WordpressStaffMemberPage> {
  return STAFF_MEMBER_PAGE_VALIDATION.safeParse(dto);
}

export const __testing__ = {transformWordpressStaffAcf};
