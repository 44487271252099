import {Result, err, ok} from '@/lib/utils/result';
import {SafeParseReturnType} from 'zod';

/**
 * Transforms a Zod safe parse result into a local Result type
 * @param zodResult - The Zod safeParse result
 * @returns A Result with the data from the Zod result or error message
 */
export function zodParseToResult<T>(
  zodResult: SafeParseReturnType<unknown, T>
): Result<T> {
  if (zodResult.success) return ok(zodResult.data);
  return err(zodResult.error.toString());
}
