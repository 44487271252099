import {Result, ok, err} from '@/lib/utils/result';
import {WordpressPageDto} from '../../types/dto/page';
import {WordpressEventPage, EventPost} from '../../types/events';
import {v} from '../validation';

const EVENT_PAGE_VALIDATION = v.object<WordpressEventPage, WordpressPageDto>({
  id: v.number('id').required(),
  slug: v.string('slug').required(),
  title: v.string('title.rendered').required(),
  content: v.string('acf.page_content').required(),
  startDate: v
    .string<Date>('acf.event_start')
    .transform((date: string) => new Date(date))
    .required(),
  endDate: v
    .string<Date>('acf.event_end')
    .transform((date: string) => new Date(date))
    .required(),
  featuredMediaId: v.number('featured_media').optional(),
  createdDate: v
    .string<Date>('date')
    .transform((date: string) => new Date(date))
    .required(),
  url: v.string('acf.registration_link').optional(),
  categories: v.array('categories', v.number().required()),
});

export function transformEventPage(
  dto: WordpressPageDto
): Result<EventPost, string> {
  // Validate using Zod
  const validation = EVENT_PAGE_VALIDATION.safeParse(dto);

  if (!validation.ok) {
    return err('Validation failed: ' + JSON.stringify(err));
  }

  // Data after successful validation
  const wordpressEvent = validation.data;

  // Transform `WordpressEventPage` to `EventPost`
  const eventPost: EventPost = {
    id: wordpressEvent.id,
    slug: wordpressEvent.slug,
    title: wordpressEvent.title,
    content: wordpressEvent.content,
    startDate: new Date(wordpressEvent.startDate),
    endDate: new Date(wordpressEvent.endDate),
    featuredMediaId: wordpressEvent.featuredMediaId,
    createdDate: new Date(wordpressEvent.createdDate),
    url: wordpressEvent.url,
    categories: wordpressEvent.categories,
  };

  return ok(eventPost);
}
