import React from 'react';
import {default as NextImage, ImageProps as NextImageProps} from 'next/image';
import {ImageMedia} from '@/lib/types/media';

type ImageProps = NextImageProps & Partial<ImageMedia>;

/**
 * Renders an image tag utilizing NextJS's image component and our own custom handling
 * @param src - The image source
 * @param alt - The alt text to display with the image
 * @param className - Optional. The classes to use for styling this image
 * @param loading - Optional. Instructs the image to eagerly or lazy load. Default: lazy
 */
export function Img(props: ImageProps) {
  const className = props.className ?? 'img-fluid';
  const image = {...props};
  delete image['__metadata'];

  return <NextImage {...image} className={className} />;
}
