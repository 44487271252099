import {Result} from '@/lib/utils/result';
import {ZodSchema} from 'zod';
import {zodParseToResult} from '../../lib/zod';
import {isObject} from '@/lib/utils/validation';

/**
 * Handles parsing of a scalar values
 * @param data - The scalar (boolean, number, string) value or the object that contains it
 * @param property - The name of the property to read for the value, if any
 * @param schema - The Zod Schema to validate the scalar value
 * @returns A Result with the validated and transformed data
 */
export function parseScalar<T>(
  data: unknown,
  property: string | undefined,
  schema: ZodSchema
): Result<T> {
  const targetData = getRequestedRuleData(data, property);
  const zodResult = schema.safeParse(targetData);
  return zodParseToResult(zodResult);
}

/**
 * Gets a property value from provided data, handling checks if the property is present of if the provided data supports properties
 * @param data - The data to read
 * @param property - The key of the property to read from
 * @returns The data if found, or undefined if not
 */
export function getRequestedRuleData(
  data: unknown,
  property: string | number | undefined
) {
  const propertyIsNumber = typeof property === 'number';
  const propertyIsString = typeof property === 'string';
  const propertyIsNotValid = !propertyIsNumber && !propertyIsString;

  if (property === undefined) return data;
  if (propertyIsNotValid) return undefined;

  if (Array.isArray(data)) {
    if (!propertyIsNumber) return undefined;
    return data[property] ?? undefined;
  }

  if (isObject(data)) {
    return deepGet(data, property) ?? undefined;
  }

  return undefined;
}

/**
 * Gets data from an object from a property regardless of depth.
 * @param data - The object to read from.
 * @param property - The property to read. Supports dot notation "foo.bar" would get the value from {foo: {bar: "value"}}.
 * @returns The data, if found. Undefined otherwise.
 */
export function deepGet(data: object, property: string | number) {
  if (!isObject(data) && !Array.isArray(data)) return undefined;
  if (typeof property === 'number') return data[property];

  const keys = property.split('.');
  let current: unknown = data;
  for (const key of keys) {
    if (!isObject(current) && !Array.isArray(current)) return undefined;
    current = current[key];
  }
  return current;
}
