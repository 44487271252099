import React, {ReactNode, useEffect} from 'react';
import {useRouter} from 'next/router';
import {extractFirstQueryValue} from '@/lib/utils/nextjs';
import {
  buildArchiveClassName,
  getArchiveTheme,
  getArchiveThemeStylesheet,
} from '@/lib/utils/archives';

/**
 * Wraps the given children with the appropriate `.archive-[archive]` CSS class for
 * applying archive-specific colors and styling. The class styles are provided
 * by a wrapping ArchiveContext component
 * @param children - The children to wrap with the archive styling
 */
export function ArchiveStyling(props: {readonly children: ReactNode}) {
  const router = useRouter();
  const archive = extractFirstQueryValue(router, 'archive');
  const archiveClassName = buildArchiveClassName(archive);

  useEffect(() => {
    updateStylesheetTheme(archive);
  }, [archive]);

  return <div className={archiveClassName}>{props.children}</div>;
}

/**
 * Updates the _document-level <link> element that sets the theme for Archive-UI.
 * @param archive - The archive slug received from the URL
 */
function updateStylesheetTheme(archive: string) {
  const stylesheetElement = document.getElementById('theme-css');
  const stylesheetElementIsInvalid = stylesheetElement?.tagName !== 'LINK';

  if (stylesheetElementIsInvalid) return;

  const archiveTheme = getArchiveTheme(archive);
  const archiveThemeStylesheet = getArchiveThemeStylesheet(archiveTheme);

  (stylesheetElement as HTMLLinkElement).href = archiveThemeStylesheet;
}

export const __testing__ = {updateStylesheetTheme};
