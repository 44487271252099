import {z, ZodLiteral} from 'zod';
import {ValidationRule} from '.';
import {Result} from '@/lib/utils/result';
import {parseScalar} from '../parse';

/** A rule for validating a value against a static value, ensuring they are equal */
export class LiteralValidationRule<
  Output extends z.Primitive
> extends ValidationRule<Output> {
  _schema: ZodLiteral<Output>;
  _default: false;

  constructor(property: string | undefined, value: Output) {
    super(property);
    this._schema = z.literal(value);
    super.required();
  }

  /**
   * @deprecated Literals are required by default
   */
  required() {
    return super.required();
  }

  _safeParse(data: unknown): Result<NoInfer<Output>> {
    return parseScalar(data, this._property, this._schema);
  }
}
