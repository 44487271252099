import {
  HierarchalNavigationDescriptor,
  LinkNavigationDescriptor,
  NavigationBehavior,
  NavigationDescriptor,
} from '@/lib/types/components/navigation';
import {
  WordpressNavigationDto,
  WordpressNavigationItemDto,
  WordpressNavigationSubItemDto,
} from '@/features/archiveAsAProduct/types/dto/navigation';
import {normalize, toKey} from '@/lib/utils/strings';
import {isObject} from '@/lib/utils/validation';
import {WordpressNavigation} from '@/features/archiveAsAProduct/types/navigation';

/**
 * Transforms the Wordpress navigation items from Wordpress into an array of objects
 * with consistent and ensured attributes.
 * @param navigationDto - The raw navigation DTO array from Wordpress
 * @param archive - The current archive to prepend to the slugs
 * @returns An array of navigation items
 */
export function transformWordpressNavigation(
  navigationDto: WordpressNavigationDto,
  archive = ''
): WordpressNavigation | undefined {
  if (!isObject(navigationDto)) return undefined;

  const archiveSlug = archive ? `/${normalize(archive)}` : '';

  const localNavigation = transformNavigationList(
    navigationDto.navigation,
    archiveSlug
  );
  const globalNavigation = transformNavigationList(
    navigationDto.global_navigation,
    archiveSlug
  );
  const branding: WordpressNavigation['branding'] = transformNavigationBranding(
    navigationDto.site_branding,
    archiveSlug
  );

  return {
    localNavigation,
    globalNavigation,
    branding,
  };
}

/**
 * Transforms a list of WordpressNavigationItem data transfer objects into a locally usable form
 * @param dto - The list of wordpress navigation items to transform
 * @param archivePath - The path of the archive to prepend to links
 * @returns An array of NavigationDescriptors
 */
function transformNavigationList(
  dto: WordpressNavigationItemDto[],
  archivePath: string
): NavigationDescriptor[] {
  if (!Array.isArray(dto)) return [];
  const navigationList = dto
    .map(navItem => transformWordpressNavigationItem(navItem, archivePath))
    .filter(navItem => navItem !== undefined);
  return navigationList;
}

/**
 * Transforms a Wordpress navigation item into a NavigationDescriptor usable with Archive-UI navigation
 * @param dto - The navigationItemDto to transform
 * @param archivePath - The current archive path to prepend to the slugs
 * @returns A formated NavigationDescriptor, or undefined if an error occured
 */
function transformWordpressNavigationItem(
  dto: WordpressNavigationItemDto,
  archivePath: string
): NavigationDescriptor | undefined {
  if (!isObject(dto)) return undefined;

  if (dto.hierarchal === true) {
    return transformWordpressHierarchalNavigation(dto, archivePath);
  }
  return transformWordpressNavigationLink(dto, archivePath);
}

/**
 * Transforms a hierachal navigation
 * @param dto - The navigation item DTO to transform
 * @param archivePath - The current archive path to prepend to the slugs
 * @returns A NavigationDescriptor on success, or undefined if not
 */
function transformWordpressHierarchalNavigation(
  dto: WordpressNavigationItemDto,
  archivePath: string
): HierarchalNavigationDescriptor | undefined {
  if (!isObject(dto) || dto.nested_navigation_items === false) return undefined;

  const navigationItems = dto.nested_navigation_items
    .map(navItem => transformWordpressNavigationLink(navItem, archivePath))
    .filter(navItem => navItem !== undefined);

  const hierarchalNavigation: HierarchalNavigationDescriptor = {
    key: toKey(dto.primary_navigation_item),
    children: dto.primary_navigation_item,
    behavior: NavigationBehavior.Hierarchal,
    navigationItems: navigationItems,
  };
  return hierarchalNavigation;
}

type UnknownNavigationItemDto = Partial<
  WordpressNavigationItemDto & WordpressNavigationSubItemDto
>;

/**
 * Transforms a Wordpress navigation subitem into a NavigationDescriptor usable with Archive-UI navigation
 * @param dto - The wordpress navigationSubItem to transform
 * @param archivePath - The path of the archive to prepend to the slugs
 * @returns A NavigationDescriptor object, if valid. Undefined if not
 */
function transformWordpressNavigationLink(
  dto: UnknownNavigationItemDto,
  archivePath: string
): LinkNavigationDescriptor | undefined {
  if (!isObject(dto)) return undefined;

  const title = dto.primary_navigation_item || dto.page_title || '';
  let link = '';
  let behavior = NavigationBehavior.Link;

  if (dto.external_link === true) {
    link = dto.external_link_url;
    behavior = NavigationBehavior.External;
  } else {
    link = dto.primary_page_slug || dto.page_slug || '';
    if (!link) return undefined;
    link = `${link}`;
  }
  if (!link) return undefined;

  const navigation: LinkNavigationDescriptor = {
    behavior,
    key: toKey(link),
    children: title,
    link,
  };
  return navigation;
}

/**
 * Transforms the brand section of the navigation data transfer object.
 * @param dto - The branding section of the WordpressNavigationDto
 * @param archiveSlug - The url slug of the current archive. Either '' or '/some-archive'
 * @returns The brand section of the WordpressNavigation
 */
function transformNavigationBranding(
  dto: WordpressNavigationDto['site_branding'],
  archiveSlug: string
): WordpressNavigation['branding'] {
  if (!isObject(dto)) return {desktopAlt: '', mobileAlt: ''};

  const linkDto = {...dto, page_slug: dto.logo_page_slug};
  const link = transformWordpressNavigationLink(linkDto, archiveSlug);
  const branding: WordpressNavigation['branding'] = {
    link,
    desktopAlt: dto.desktop_logo?.alt ?? '',
    mobileAlt: dto.mobile_logo?.alt ?? '',
    desktopLogoId: dto.desktop_logo?.id || undefined,
    mobileLogoId: dto.mobile_logo?.id || undefined,
  };

  return branding;
}

export const __testing__ = {
  transformWordpressNavigationItem,
  transformWordpressHierarchalNavigation,
  transformNavigationBranding,
};
