import {NextRouter} from 'next/router';

/**
 * Extracts the query value into a string. If an array, grabs the first one
 * @param ctx - A Next Page context or router object containing access to a query object
 * @param key - The key of the value(s) to extract from the query
 * @returns A string containing the query value. If none is found, undefined
 */
export function extractFirstQueryValue(
  ctx: {query: Record<string, string | string[]>},
  key: string
): string | undefined {
  if (typeof ctx !== 'object' || !ctx?.query) return undefined;

  const queryValue = ctx.query[key];
  if (typeof queryValue === 'string') return queryValue;
  if (!Array.isArray(queryValue)) return undefined;
  if (queryValue.length === 0) return undefined;
  return queryValue[0];
}

/**
 *
 * @param ctx next context object
 * @param key key you want to extarct
 * @returns an array containing strings that match the key
 */
export function extractPathFromQueryArray(
  ctx: {query: Record<string, string | string[]>},
  key: string
): string | undefined {
  if (typeof ctx !== 'object' || !ctx?.query) return undefined;

  const queryValue = ctx.query[key];
  if (typeof queryValue === 'string') return queryValue;
  if (!Array.isArray(queryValue)) return undefined;
  return queryValue.join('/');
}

/**
 * Gets study URL
 * @param ctx - A Next Page context or router object containing access to a query object
 * @param key - The key of the value(s) to extract from the query
 * @returns A string containing the query value. If none is found, undefined
 */
export function getBaseStudyUrl(router: NextRouter, studyId: number): string {
  const archive = extractFirstQueryValue(router, 'archive');
  let parentStudyHref = `/view/studies/${studyId}`;
  if (archive) parentStudyHref = `/${archive}${parentStudyHref}`;
  return parentStudyHref;
}
