import {
  WordpressImageMedia,
  WordpressMedia,
} from '@/features/archiveAsAProduct/types/media';
import {isObject} from '@/lib/utils/validation';
import {WordpressMediaContentDto} from '@/features/archiveAsAProduct/types/dto/media/content';

/**
 * Transforms a media data transfer object into a standard WordpressMedia object
 * @param dto - The data transfer object describing a piece of media
 * @returns A WordpressMedia object
 */
export function transformWordpressMediaContent(
  dto: WordpressMediaContentDto | undefined
): WordpressMedia | undefined {
  if (!isObject(dto)) return undefined;

  switch (dto.type) {
    case 'image':
      return transformWordpressImageMedia(dto);
    default:
      return undefined;
  }
}

/**
 * Transforms a WordpressImageContentDto into a locally usable object
 * @param dto - The WordpressImageContent data transfer object to transform
 * @returns A WordpressImage media object
 */
function transformWordpressImageMedia(
  dto: WordpressMediaContentDto
): WordpressImageMedia | undefined {
  if (!dto.url) return undefined;

  const media: WordpressImageMedia = {
    __metadata: {
      source: 'wordpress',
      id: dto.id ?? 0,
      title: dto.title ?? '',
      mimetype: dto.mime_type ?? '',
    },
    alt: dto.alt ?? '',
    src: dto.url,
    type: 'image',
    subtype: dto.subtype ?? '',
    height: dto.height || undefined,
    width: dto.width || undefined,
  };
  return media;
}
