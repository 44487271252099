import {WordpressLink} from '@/features/archiveAsAProduct';
import React from 'react';

type NewsletterButtonProps = Readonly<{
  newsletter: WordpressLink;
}>;

/**
 * Renders a Newsletter signup button. The default ICPSR values can be overridden
 * @param newsletter - An object containing a title and href to use in the newsletter button
 */
export function NewsletterButton(props: NewsletterButtonProps) {
  if (!props.newsletter?.href) return null;

  return (
    <a className="btn btn-outline-primary" href={props.newsletter.href}>
      {props.newsletter.title}
    </a>
  );
}
