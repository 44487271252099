import {Result} from '@/lib/utils/result';
import {WordpressPageDto} from '../../types/dto/page';
import {WordpressFaqPage} from '../../types/faq';
import {v} from '../validation';

const FAQ_PAGE_VALIDATION = v.object<WordpressFaqPage, WordpressPageDto>({
  id: v.number('id').required(),
  slug: v.string('slug').required(),
  title: v.string('acf.seo.title_tag').required(),
  content: v.string('acf.content'),
});

export function transformFaqPage(
  dto: WordpressPageDto
): Result<WordpressFaqPage> {
  return FAQ_PAGE_VALIDATION.safeParse(dto);
}
