import React from 'react';
import {SocialMediaLinks} from '@/lib/types/components/socialMedia';
import {FooterSocialMedia} from '../../footer/FooterSocialMedia';
import {ImageMedia} from '@/lib/types/media';
import {NewsletterButton} from '../../footer/NewsletterButton';
import {FooterLogo} from '../../footer/FooterLogo';
import UofMLogo from '../../../../public/images/icpsr-acronym-long-WHITE-CS6.svg';
import {isImageMedia} from '@/lib/utils/validation';
import {WordpressLink} from '@/features/archiveAsAProduct';
import {useRouter} from 'next/router';
import {extractFirstQueryValue} from '@/lib/utils/nextjs';
import {normalize} from '@/lib/utils/strings';
import {WordpressFooter} from '../../../features/archiveAsAProduct/types/footer';

export function ArchiveFooter(props: WordpressFooter) {
  const router = useRouter();
  const archive = extractFirstQueryValue(router, 'archive');

  const shouldUseDefaults = shouldUseIcpsrDefaults(archive);

  const socialMediaLinks: SocialMediaLinks = shouldUseDefaults
    ? defaultSocialMediaLinks
    : props.socialMediaLinks;
  const newsletter = shouldUseDefaults ? defaultNewsletter : props.newsletter;
  const logo = isImageMedia(props.icpsrLogo) ? props.icpsrLogo : defaultLogo;
  const additionalLogosElements: JSX.Element[] | undefined =
    props.additionalLogos?.map(({logo, link, logo_height}, index) => (
      <FooterLogo
        logo={logo}
        link={link || undefined}
        key={`${logo.alt}-${index}`}
        logoHeight={logo_height}
      />
    ));

  /**
   * Determines if the link should be displayed
   * @param link - The WordpressLink object
   * @returns boolean - Whether the link has a valid URL to be displayed
   */
  function shouldDisplayLink(link?: WordpressLink): boolean {
    return !!link && link.href !== '';
  }

  const accessibilityHref = props.accessibility?.href?.trim()
    ? props.accessibility.href
    : defaultAccessibilityHref;

  const privacyPolicyHref = props.privacyPolicy?.href?.trim()
    ? props.privacyPolicy.href
    : defaultPrivacyPolicyHref;

  const additionalLinks = determineAdditionalLinks(
    props.additionalLinks,
    shouldUseDefaults
  );
  const additionalLinkElements = additionalLinks
    .filter(shouldDisplayLink) // Filter to ensure type safety and proper display.
    .map(link => <FooterLink key={link.title} {...link} />);

  return (
    <footer className="site-footer" data-testid="icpsr-page-footer">
      <div className="footer-action-bar">
        <div className="container">
          {props.socialMediaLinks && (
            <FooterSocialMedia socialMediaLinks={socialMediaLinks} />
          )}
          <ul className="footer-links align-items-center justify-content-center">
            {accessibilityHref && (
              <FooterLink title="Accessibility" href={accessibilityHref} />
            )}
            {privacyPolicyHref && (
              <FooterLink title="Privacy Policy" href={privacyPolicyHref} />
            )}
            {additionalLinkElements}
          </ul>

          <NewsletterButton newsletter={newsletter} />
        </div>
      </div>
      <div className="footer-content container">
        <div className="footer-logos align-items-md-end">
          <div className="footer-um-logo">
            <a href="https://www.icpsr.umich.edu/">
              <FooterLogo logo={logo} className="" />
            </a>
          </div>

          {props.additionalLogos?.length !== 0 && (
            <div className="footer-archive-logos">
              {additionalLogosElements}
            </div>
          )}
        </div>
        {props.textBlurb && (
          <div
            className="footer-extra"
            dangerouslySetInnerHTML={{__html: props.textBlurb}}
          />
        )}
        <div className="footer-copyright">
          <p>
            &copy; {new Date().getFullYear()} The{' '}
            <a href="http://regents.umich.edu/">
              Regents of the University of Michigan
            </a>
            . ICPSR is part of the{' '}
            <a href="http://www.isr.umich.edu/">
              Institute for Social Research
            </a>{' '}
            at the <a href="http://www.umich.edu/">University of Michigan</a>.
          </p>
        </div>
      </div>
    </footer>
  );
}

function shouldUseIcpsrDefaults(archive: string) {
  if (!archive) return true;
  const normalizedArchive = normalize(archive);
  return normalizedArchive === 'icpsr' || normalizedArchive === '';
}

function FooterLink(props: Readonly<WordpressLink>) {
  if (!props.href || props.href.trim() === '') return null;

  return (
    <li>
      <a href={props.href} target={props.target}>
        {props.title}
      </a>
    </li>
  );
}

function determineAdditionalLinks(
  additionalLinks: WordpressLink[] | undefined,
  useDefaults: boolean
): WordpressLink[] {
  if (useDefaults || !additionalLinks) return defaultAdditionalLinks;
  if (!Array.isArray(additionalLinks)) return [];
  return additionalLinks.filter(link => link.href && link.title);
}

const defaultSocialMediaLinks: SocialMediaLinks = {
  facebook: {
    screenReaderText: 'Facebook',
    href: 'https://www.facebook.com/pages/ICPSR/43191688396',
  },
  instagram: {
    screenReaderText: 'Instagram',
    href: 'https://www.instagram.com/theicpsr/',
  },
  twitter: {
    screenReaderText: 'Twitter',
    href: 'https://www.twitter.com/#!/icpsr',
  },
  linkedIn: {
    screenReaderText: 'LinkedIn',
    href: 'https://www.linkedin.com/company/ICPSR',
  },
  youtube: {
    screenReaderText: 'YouTube',
    href: 'https://www.youtube.com/channel/UC4__DY4PxAg4Fjspbubd-Pw',
  },
  bluesky: {
    screenReaderText: 'Bluesky',
    href: 'https://bsky.app/profile/icpsr.bsky.social',
  },
};
const defaultLogo: ImageMedia = {
  src: UofMLogo,
  height: 40,
  width: 293,
  alt: 'ICPSR - Institute for Social Research - University of Michigan',
  type: 'image',
  subtype: 'svg',
};
const defaultNewsletter = {
  title: 'Sign up for our newsletter',
  href: 'https://myumi.ch/ICPSRAnnounce',
};
const defaultAccessibilityHref = 'https://myumi.ch/ICPSRAccessibilityWebFoot';
const defaultPrivacyPolicyHref = 'https://myumi.ch/ICPSRPrivacyPolicyWebFoot';

const defaultAdditionalLinks: WordpressLink[] = [
  {
    title: 'Contact Us',
    href: 'https://myumi.ch/ICPSRContactUsWebFooter',
  },
  {
    title: 'Help',
    href: 'https://www.icpsr.umich.edu/web/about/cms/5078',
  },
  {
    title: 'ICPSR-help@umich.edu',
    href: 'mailto:ICPSR-help@umich.edu',
  },
  {
    title: '734-647-2200',
    href: 'tel:734-647-2200',
  },
];

export default ArchiveFooter;
export const __testing__ = {
  defaultSocialMediaLinks,
  defaultLogo,
  defaultNewsletter,
  defaultAccessibilityHref,
  defaultPrivacyPolicyHref,
};
