import {useState, useEffect} from 'react';
import {loadSiteBanner} from '@/features/archiveAsAProduct/utils/loaders';
import {SiteBanner} from '@/features/archiveAsAProduct/types/banner';

export function useSiteBanner(archive: string) {
  const [banner, setBanner] = useState<SiteBanner | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchBanner() {
      setLoading(true);
      try {
        const loadedBanner = await loadSiteBanner(archive);
        setBanner(loadedBanner || null);
      } catch (e: unknown) {
        const errorMessage =
          e instanceof Error ? e.message : 'Error fetching banner';
        setError(errorMessage);
      }
      setLoading(false);
    }
    fetchBanner();
  }, [archive]);

  return {banner, loading, error};
}
