import {SafeCustomHtml} from '@/components/SafeCustomHtml';
import React from 'react';
import {v} from '../../utils/validation';
import {SectionFC} from '../../types/templates';
import {WordpressSanitizationConfig} from '../helpers/SanitizationConfig';

/**
 * Renders an element containing safely sanitized HTML for Wordpress
 * @param customField The custom field containing the plain HTML to render
 */
export const PlainHtml: SectionFC<string> = props => {
  return (
    <div className="wp-content">
      <SafeCustomHtml
        html={props.content}
        config={WordpressSanitizationConfig}
      />
    </div>
  );
};

PlainHtml.fields = v.string();
