import {SearchEngineOptimization} from '@/lib/types/searchEngineOptimization';
import {isObject} from '@/lib/utils/validation';
import Head from 'next/head';
import React from 'react';

type SiteMetadataProps = Readonly<{
  seo: SearchEngineOptimization | undefined;
}>;

/**
 * Applies the metadata to the HTML head
 * @param seo - The search engine optimization metadata
 */
export function SiteMetadata(props: SiteMetadataProps) {
  if (!isObject(props.seo)) return null;
  const hasDescription = !!props.seo.description;

  return (
    <Head>
      {hasDescription && (
        <meta name="description" content={props.seo.description} />
      )}
    </Head>
  );
}
