import {WordpressSeoDto} from '@/features/archiveAsAProduct/types/dto/seo';
import {isObject} from '@/lib/utils/validation';
import {SearchEngineOptimization} from '@/lib/types/searchEngineOptimization';

/**
 * Transforms a search engine optimization data transfer object into a standard
 * WordpressSeo object
 * @param dto - The data transfer object describing the fields for search engine
 *  optimization
 * @returns A WordpressSeo object
 */
export function transformWordpressSeo(
  dto: WordpressSeoDto
): SearchEngineOptimization {
  if (!isObject(dto)) return {title: ''};

  const seo: SearchEngineOptimization = {
    title: dto.title_tag ?? '',
    description: dto.meta_description,
  };
  return seo;
}
