import {ok, Result} from '@/lib/utils/result';
import {ValidationRule} from '.';

/** A rule to create static data when validation and transformation is run */
export class StaticRule<Output> extends ValidationRule<Output> {
  _value: Output;

  constructor(value: Output) {
    super(undefined);
    this._value = value;
  }

  _safeParse(): Result<Output> {
    // TODO - deep copy this data if an object or array is provided
    return ok(this._value);
  }
}
