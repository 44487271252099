import {z} from 'zod';
import {v} from '.';
import {WordpressImageMedia} from '../../types/media';
import {getArrayArguments, getObjectArguments} from './arguments';
import {ValidationRule} from './rules';
import {ArrayValidationRule} from './rules/array';
import {BooleanValidationRule} from './rules/boolean';
import {LiteralValidationRule} from './rules/literal';
import {NumberValidationRule} from './rules/number';
import {ObjectChildRules, ObjectValidationRule} from './rules/object';
import {StaticRule} from './rules/static';
import {StringValidationRule} from './rules/string';

export class ValidationRuleFactory {
  /** The end of the current rule chain */
  _current: ValidationRule<unknown> | undefined;

  constructor(current?: ValidationRule<unknown>) {
    if (!current) return;
    this._current = current;
  }

  /**
   * Inserts the new rule into the existing rule chain
   * @param rule - The new rule to add to the chain, if any
   */
  _applyRelatives<T extends ValidationRule<unknown>>(rule: T): T {
    const ruleIsBeginningOfChain = !this._current;
    if (ruleIsBeginningOfChain) return rule;

    rule._setParent(this._current._parent);
    this._current._setNext(rule);
    return rule;
  }

  /**
   * Creates a rule for an array
   * @param content - The rule for the content within the array
   */
  array<Output>(content: ValidationRule<Output>): ArrayValidationRule<Output>;
  /**
   * Creates a rule for an array stored within the specified field
   * @param property - The property in which the array is stored
   * @param content - The rule for the content within the array
   */
  array<Output>(
    property: string,
    content: ValidationRule<Output>
  ): ArrayValidationRule<Output>;

  array<Output>(
    arg1: string | ValidationRule<Output>,
    arg2?: ValidationRule<Output>
  ): ArrayValidationRule<Output> {
    const {property, content} = getArrayArguments(arg1, arg2);
    let rule;
    if (property) {
      rule = new ArrayValidationRule(property, content);
    } else {
      rule = new ArrayValidationRule(content);
    }
    return this._applyRelatives(rule);
  }

  /**
   * Creates a rule for an object
   * @param content - The rules for the content to be placed within this object
   */
  object<Output, Input = Output>(
    content: ObjectChildRules<Output>
  ): ObjectValidationRule<Output, Input>;
  /**
   * Creates a rule for an object stored within the specified field
   * @param property - The property in which the object is stored
   * @param content - The rules for the content to be placed within this object
   */
  object<Output, Input = Output>(
    property: string,
    content: ObjectChildRules<Output>
  ): ObjectValidationRule<Output, Input>;

  object<Output = Record<number | string, unknown>, Input = Output>(
    arg1: string | ObjectChildRules<Output>,
    arg2?: ObjectChildRules<Output>
  ): ObjectValidationRule<Output, Input> {
    const {property, content} = getObjectArguments(arg1, arg2);
    let rule;
    if (property) {
      rule = new ObjectValidationRule<Output, Input>(property, content);
    } else {
      rule = new ObjectValidationRule<Output, Input>(content);
    }
    return this._applyRelatives(rule);
  }

  /**
   * Creates a rule for a boolean.
   * @param property - Optional. The property in which this boolean is stored
   */
  boolean(property?: string): BooleanValidationRule {
    const rule = new BooleanValidationRule(property);
    return this._applyRelatives(rule);
  }

  /**
   * Creates a rule for comparing to a static value. Equivalent to `.string().equals(value)` for primitive types
   * @param value - The literal value to compare to
   */
  literal<Output extends z.Primitive>(
    value: Output
  ): LiteralValidationRule<Output>;
  /**
   * Creates a rule for comparing to a static value within a given property. Equivalent to `.string().equals(value)` for primitive types
   * @param property - The property containing the value to compare against the literal
   * @param value - The literal value to compare to
   */
  literal<Output extends z.Primitive>(
    property: string,
    value: Output
  ): LiteralValidationRule<Output>;

  literal<Output extends z.Primitive>(
    arg1: string | Output,
    arg2?: Output | undefined
  ) {
    const arg1IsString = typeof arg1 === 'string';
    const arg2IsPresent = arg2 !== undefined;
    const arg1IsProperty = arg1IsString && arg2IsPresent;

    const property = arg1IsProperty ? arg1 : undefined;
    const value = arg1IsProperty ? arg2 : arg1;

    const rule = new LiteralValidationRule(property, value);
    return this._applyRelatives(rule);
  }

  /**
   * Creates a rule for a bumber.
   * @param property - Optional. The property in which this number is stored
   */
  number(property?: string): NumberValidationRule {
    const rule = new NumberValidationRule(property);
    return this._applyRelatives(rule);
  }

  /**
   * Creates a rule for a string.
   * @param property - Optional. The property in which this string is stored
   */
  string<Output = string>(property?: string) {
    const rule = new StringValidationRule<Output>(property);
    return this._applyRelatives(rule);
  }

  /**
   * Inserts static data into the object being validated.
   * @param value - The static data to insert
   */
  static<T>(value: T): StaticRule<T> {
    const rule = new StaticRule(value);
    return this._applyRelatives(rule);
  }

  /**
   * Creates pre-build rules for the different kinds of media objects
   * @param property - Optional. The property in which this string is stored
   */
  media(property?: string): ObjectValidationRule<WordpressImageMedia> {
    const metadataRules = v.object<WordpressImageMedia['__metadata']>({
      id: v.number('id'),
      title: v.string('title.rendered'),
      mimetype: v.string('mime_type'),
      source: v.static('wordpress'),
    });

    const rule = v.object<WordpressImageMedia>(property, {
      __metadata: metadataRules,
      src: v.string('source_url').required(),
      alt: v.string('alt_text'),
      height: v.number('height').optional(),
      width: v.number('height').optional(),
      type: v.static('image'),
      subtype: v.static(undefined),
    });

    rule.or().object<WordpressImageMedia>(property, {
      __metadata: metadataRules,
      src: v.string('url').required(),
      alt: v.string('alt'),
      height: v.number('height').optional(),
      width: v.number('height').optional(),
      type: v.static('image'),
      subtype: v.string('subtype'),
    });

    return rule;
  }
}
