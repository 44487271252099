import {WordpressNavigation} from '@/features/archiveAsAProduct/types/navigation';
import {isValidHttpResponse, safeHttpCall} from '@/lib/utils/http';
import {transformWordpressNavigation} from './transform/navigation';
import {
  WordpressImageMedia,
  WordpressMedia,
} from '@/features/archiveAsAProduct/types/media';
import {WordpressFooter} from '@/features/archiveAsAProduct/types/footer';
import {transformWordpressFooter} from './transform/footer';
import {ImageMedia} from '@/lib/types/media';
import longIcpsrLogo from '../../../../public/images/icpsr-acronym-long-WHITE.png';
import {transformWordpressMedia} from './transform/media';
import {
  WordpressStaff,
  WordpressStaffMemberPage,
} from '@/features/archiveAsAProduct/types/staff';
import {
  transformStaffMemberPage,
  transformWordpressStaff,
} from './transform/staff';
import {
  fetchEventPost,
  fetchFaq,
  fetchNewsPost,
  fetchEvents,
  fetchStaffMember,
  fetchArchiveCategories,
  fetchNews,
  fetchNewsCategories,
  fetchTags,
  fetchUser,
  getAllStaff,
  getFeaturedResource,
  getResourcesAndTraining,
  getWordpressFooter,
  getWordpressLogo,
  getWordpressMedia,
  getWordpressNavigation,
  getWordpressPage,
  fetchArchivePostCategories,
  fetchArchivePostsByCategory,
  fetchSidebarContent,
} from './api/wordpress';
import {WordpressPageDto} from '../types/dto/page';
import {WordpressFunderLogo} from '../types/dto/media/content';
import {WordpressFunderLogoDto} from '../types/dto/media/funderLogo';
import {isNonEmptyString} from '@/lib/utils/validation';
import {HttpResponse} from '@/lib/types/http';
import {parseFunderLogo} from './transform/funderLogo';
import {WordpressNavigationDto} from '../types/dto/navigation';
import {WordpressImagePageDto} from '../types/dto/media/page';
import {WordpressFooterDto} from '../types/dto/footer';
import {logger, parseError} from '@/lib/utils/logging';
import {err, ErrResult, ok, Result} from '@/lib/utils/result';
import {
  transformFeaturedResource,
  transformFeaturedResources,
} from './transform/featuredResourse';
import {FeaturedResourceDto} from '../types/dto/resource';
import {FeaturedResource} from '../types/resource';
import {transformNewsPage} from './transform/news';
import {WordpressNewsPage} from '../types/news';
import {EventPost, EventsSearchOptions} from '../types/events';
import {WordpressFaqPage} from '../types/faq';
import {transformEventPage} from './transform/event';
import {transformFaqPage} from './transform/faq';
import {transformCategories} from './transform/categories';
import {CategoryDto} from '../types/dto/category';
import {Category, CategoryPostType} from '../types/category';
import {NewsPost, NewsSearchOptions} from '../types/news';
import {transformNewsPosts} from './transform/news';
import {WordpressUser} from '../types/user';
import {transformUser} from './transform/user';
import {transformArchivePosts} from './transform/archivePosts';
import {transformSidebar} from './transform/sidebar';
import {transformSiteBanner} from '@/features/archiveAsAProduct/utils/transform/banner';
import {getSiteBanners} from './api/sitebanner';
import {SiteBanner} from '@/features/archiveAsAProduct/types/banner';
import {SiteBannerDto} from '../types/dto/banner';

const fileLogger = logger('archiveAsAProduct/loaders.ts');

/**
 * Loads the navigation information for the given archive from Wordpress
 * @param archive - The archive to load navigation for
 * @returns A WordpressNavigation object. Undefined if invalid or not found
 */
export async function loadWordpressNavigation(
  archive: string
): Promise<WordpressNavigation> {
  const log = fileLogger('loadWordpressNavigation');
  let navigationResult: HttpResponse<WordpressNavigationDto>;
  try {
    navigationResult = await getWordpressNavigation(archive);
  } catch (e: unknown) {
    log('error', parseError(e));
    return undefined;
  }

  if (!isValidHttpResponse(navigationResult)) {
    console.error(`The Wordpress navigation for ${archive} failed to fetch.`);

    return defaultNavigation;
  }

  const navigationDto = navigationResult.data;
  const navigation = transformWordpressNavigation(navigationDto, archive);
  if (!navigation) return defaultNavigation;

  const logoPromises = [
    loadWordpressMedia(archive, navigation.branding?.desktopLogoId),
    loadWordpressMedia(archive, navigation.branding?.mobileLogoId),
  ];

  const logos: (WordpressImageMedia | undefined)[] = await Promise.all(
    logoPromises
  );
  navigation.branding.desktopLogo = logos[0];
  navigation.branding.mobileLogo = logos[1];

  if (navigation.branding.desktopLogo) {
    navigation.branding.desktopLogo.alt = navigation.branding.desktopAlt;
  }
  if (navigation.branding.mobileLogo) {
    navigation.branding.mobileLogo.alt = navigation.branding.mobileAlt;
  }

  return navigation;
}

/**
 * Fetches, validates, and transforms Wordpress page data for the given archive and slug
 * @param archive - The archive to load this page from
 * @param slug - The slug following the URL
 * @returns The transformed WordpressPage data, if found. Undefined if not.
 */
export async function loadWordpressPage(
  archive: string,
  slug: string
): Promise<WordpressPageDto | undefined> {
  const log = fileLogger('loadWordpressPage');
  let pageResult: HttpResponse<WordpressPageDto>;
  try {
    pageResult = await getWordpressPage(archive, slug);
  } catch (e: unknown) {
    log('error', parseError(e));
    return undefined;
  }

  if (!isValidHttpResponse(pageResult)) {
    log(
      'error',
      `The Wordpress page for ${archive} with slug ${slug} failed to fetch.`
    );
    return undefined;
  }

  if (Array.isArray(pageResult.data)) {
    log(
      'error',
      `The Wordpress page for ${archive} with slug ${slug} returned multiple pages`
    );
    return undefined;
  }

  const pageDto = pageResult.data;
  return pageDto;
}

/**
 * Fetches a logo for a given funder
 * @param funder - The funder to fetch the logo for
 * @returns A funder logo, if retreived. Undefined otherwise
 */
export async function loadWordpressFunderLogo(
  funder: string
): Promise<WordpressFunderLogo | undefined> {
  if (!isNonEmptyString(funder)) return undefined;

  const log = fileLogger('loadWordpressFunderLogo');
  let funderLogoResponse: HttpResponse<WordpressFunderLogoDto[]>;
  try {
    funderLogoResponse = await getWordpressLogo(funder);
  } catch (e: unknown) {
    log('error', parseError(e));
    return undefined;
  }

  if (!isValidHttpResponse(funderLogoResponse)) return undefined;

  const funderLogoDto = funderLogoResponse.data;
  const funderLogo = parseFunderLogo(funderLogoDto);
  return funderLogo;
}

/**
 * Loads a piece of media from Wordpress, validates it, and transforms it into
 * a locally usable form
 * @param archive - The archive to load the media from
 * @param mediaId - The ID of the media to load
 * @returns A WordpressMedia object. Undefined if invalid or not found
 */
export async function loadWordpressMedia(
  archive: string,
  mediaId: number | string | undefined
): Promise<WordpressMedia | undefined> {
  if (!mediaId) return undefined;
  const log = fileLogger('loadWordpressMedia');

  let mediaResult: HttpResponse<WordpressImagePageDto>;
  try {
    mediaResult = await getWordpressMedia(archive, mediaId);
  } catch (e: unknown) {
    log('error', parseError(e));
    return undefined;
  }

  if (!isValidHttpResponse(mediaResult)) {
    log(
      'error',
      `The Wordpress media for ${archive} with ID ${mediaId} failed to fetch. ${mediaResult.message}`
    );

    return undefined;
  }

  const mediaDto = mediaResult.data;
  const media = transformWordpressMedia(mediaDto);
  return media;
}

/**
 * Loads the footer information for a given archive from Wordpress,
 * validating and transforming it before returning
 * @param archive - The archive to load the footer for
 * @returns A WordpressFooter object containing the received data
 */
export async function loadWordpressFooter(
  archive: string
): Promise<WordpressFooter | undefined> {
  const log = fileLogger('loadWordpressFooter');
  const fallbackFooter = transformWordpressFooter(undefined, archive);
  fallbackFooter.logo = defaultFooterLogo;

  let footerResult: HttpResponse<WordpressFooterDto>;
  try {
    footerResult = await getWordpressFooter(archive);
  } catch (e: unknown) {
    log('error', parseError(e));
    return fallbackFooter;
  }
  if (!isValidHttpResponse(footerResult)) {
    log('error', `The Wordpress footer for ${archive} failed to fetch.`);
    return fallbackFooter;
  }
  const footerDto = footerResult.data;
  const footer = transformWordpressFooter(footerDto, archive);

  footer.logo = defaultFooterLogo;

  return footer;
}

/**
 * Fetches all staff members for a given archive
 * @param archive - The archive to fetch staff members from
 * @returns An array of transformed Wordpress staff objects
 */
export async function loadAllStaffMembers(
  archive: string
): Promise<WordpressStaff[]> {
  if (!archive) return [];

  const staffResult = await getAllStaff(archive);
  if (!isValidHttpResponse(staffResult)) throw new Error(staffResult.message);

  const staff = staffResult.data.map(transformWordpressStaff);
  return staff;
}

/**
 * Loads, validates, and returns a Featured Resource for the provided data type and ID
 * @param dataType - The specific type of data to fetch the featured resource for
 * @param id - The ID of the data type
 * @returns A Result containing a Featured Resource or undefined.
 */
export async function loadFeaturedResource(
  dataType: 'study' | 'collection',
  id: number | string
): Promise<Result<FeaturedResource | undefined>> {
  let result: HttpResponse<FeaturedResourceDto[]>;
  try {
    result = await getFeaturedResource(dataType, id);
  } catch (e) {
    return err(e);
  }

  if (!isValidHttpResponse(result)) return err(result.message);

  if (!result.data || result.data.length === 0) return err('No data available');

  const featuredResource = transformFeaturedResource(result.data[0]);
  return ok(featuredResource);
}

/**
 * Loads, validates, and returns an array of resources for the provided data type and ID
 * @param dataType - The specific type of data to fetch the resources for
 * @param id - The ID of the data type
 * @returns A Result containing an array of Resources
 */
export async function loadResourcesAndTraining(
  method: 'study' | 'collection',
  id: number | undefined
): Promise<Result<FeaturedResource[]>> {
  if (id === undefined) return ok([]);

  let result: HttpResponse<FeaturedResourceDto[]>;
  try {
    result = await getResourcesAndTraining(method, id);
  } catch (e) {
    return err(e);
  }

  if (!isValidHttpResponse(result)) return err(result.message);
  const resources: FeaturedResource[] = transformFeaturedResources(result.data);
  return ok(resources);
}

/**
 * Loads the page data for an event
 * @param slug - The unique identifying slug of the event
 * @returns A Result containing the page data
 */
export async function loadEventPage(
  slug: string
): Promise<Result<EventPost, string>> {
  const result = await safeHttpCall(() => fetchEventPost(slug));
  if (!result.ok) return result as ErrResult;
  return transformEventPage(result.data);
}

/**
 * Loads the page data for an FAQ
 * @param slug - The unique identifying slug of the FAQ
 * @returns A Result containing the page data
 */
export async function loadFaqPage(
  slug: string
): Promise<Result<WordpressFaqPage>> {
  const result = await safeHttpCall(() => fetchFaq(slug));
  if (!result.ok) return result as ErrResult;
  return transformFaqPage(result.data);
}

/**
 * Loads the page data for an news post
 * @param slug - The unique identifying slug of the news post
 * @returns A Result containing the page data
 */
export async function loadNewsPostPage(
  slug: string
): Promise<Result<WordpressNewsPage>> {
  const result = await safeHttpCall(() => fetchNewsPost(slug));
  if (!result.ok) return result as ErrResult;
  return transformNewsPage(result.data);
}

/**
 * Loads the page data for an staff member
 * @param slug - The unique identifying slug of the staff member
 * @returns A Result containing the page data
 */
export async function loadStaffMemberPage(
  archive: string,
  slug: string
): Promise<Result<WordpressStaffMemberPage>> {
  const result = await safeHttpCall(() => fetchStaffMember(archive, slug));
  if (!result.ok) return result as ErrResult;
  return transformStaffMemberPage(result.data);
}

/*
 * Loads, validates, and transforms news categories
 * @returns An array of Categories
 */
export async function loadNewsCategories(): Promise<Result<Category[]>> {
  return loadGenericCategories(() => fetchNewsCategories());
}

/**
 * Loads and transforms user data
 * @returns an object containing first name and last name
 */
export async function loadUser(userId: number): Promise<Result<WordpressUser>> {
  const result = await fetchUser(userId);
  if (isValidHttpResponse(result)) {
    return transformUser(result.data);
  } else {
    return err('An error occured fetching the user');
  }
}

/**
 * Loads, validates, and transforms archive categories for a specific post type
 * @param postType - The post type to fetch
 * @returns An array of Categories
 */
export async function loadArchiveCategories(postType: CategoryPostType) {
  return loadGenericCategories(() => fetchArchiveCategories(postType));
}

/**
 *
 * @param archive archive name from url
 * @returns
 */
export async function loadArchivePostCategories(archive: string) {
  return loadGenericCategories(() => fetchArchivePostCategories(archive));
}

/**
 * Loads, validates, and transforms tags for a specific post type
 * @param postType - The post type to fetch
 * @returns An array of categories
 */
export async function loadTags(postType: CategoryPostType) {
  return loadGenericCategories(() => fetchTags(postType));
}

/**
 * Loads, validates, and transforms categories retrieved by a fetcher callback
 * @param fetcher - The API request that returns an HTTP Response containing an array of Categories
 * @returns A result with an array of Categories
 */
async function loadGenericCategories(
  fetcher: () => Promise<HttpResponse<CategoryDto[]>>
): Promise<Result<Category[]>> {
  let result: HttpResponse<CategoryDto[]>;
  try {
    result = await fetcher();
  } catch (e) {
    return err(e);
  }

  if (!isValidHttpResponse(result)) return err(result.message);
  const categories = transformCategories(result.data);
  return categories;
}

export async function loadNews(
  options: NewsSearchOptions
): Promise<Result<NewsPost[]>> {
  let result: HttpResponse<WordpressPageDto[]>;
  try {
    result = await fetchNews(options);
  } catch (e) {
    return err(e);
  }

  if (!isValidHttpResponse(result)) return err(result.message);
  const newsPosts = transformNewsPosts(result.data);
  return ok(newsPosts);
}

export async function loadSidebar(slug: string, archive: string) {
  let result;
  try {
    result = await fetchSidebarContent(slug, archive);
  } catch (e) {
    return err(e);
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  if (!isValidHttpResponse(result)) {
    return err(result.message);
  }
  const sidebarContent = transformSidebar(result);
  return sidebarContent;
}
/**
 *
 * @param archive string from url
 * @returns a list of all non news posts for the archive you're currently on
 */
export async function loadArchivePosts(
  archive: string,
  categoryId: number | number[]
) {
  let result: HttpResponse<WordpressPageDto[]>;
  try {
    result = await fetchArchivePostsByCategory(archive, categoryId);
  } catch (e) {
    return err(e);
  }
  if (!isValidHttpResponse(result)) return err(result.message);
  const archivePosts = transformArchivePosts(result.data);
  return archivePosts;
}

export async function loadEvents(
  options: EventsSearchOptions
): Promise<Result<EventPost[]>> {
  let result: HttpResponse<WordpressPageDto[]>;
  try {
    result = await fetchEvents(options);
  } catch (e) {
    return err(e);
  }

  if (!isValidHttpResponse(result)) return err(result.message);

  // Transform each event in the result.data array
  const transformedEvents: EventPost[] = [];

  for (const dto of result.data) {
    const transformResult = transformEventPage(dto);
    if (transformResult.ok) {
      transformedEvents.push(transformResult.data);
    } else {
      console.error('Error transforming event:', err);
    }
  }

  return ok(transformedEvents);
}

/** The default navigation object */
const defaultNavigation: WordpressNavigation = {
  localNavigation: [],
  globalNavigation: [],
  branding: {
    mobileAlt: '',
    desktopAlt: '',
  },
};

/** The default footer for all archives */
const defaultFooterLogo: ImageMedia = {
  src: longIcpsrLogo,
  height: 40,
  width: 293,
  alt: 'ICPSR - Institute for Social Research - University of Michigan',
  type: 'image',
  subtype: 'png',
};

export const __testing__ = {
  defaultNavigation,
  loadGenericCategories,
};

/**
 * Loads the active site banner for the given archive.
 * Returns the first banner (if any) where:
 *   - the archive is listed in banner_sites, and
 *   - the current EST time is between the banner's start and end times.
 */
export async function loadSiteBanner(
  archive: string
): Promise<SiteBanner | undefined> {
  const log = fileLogger('loadSiteBanner');
  let bannerResult: HttpResponse<SiteBannerDto[]>;
  try {
    bannerResult = await getSiteBanners();
  } catch (e: unknown) {
    log('error', parseError(e));
    return undefined;
  }

  if (!isValidHttpResponse(bannerResult)) {
    console.error(`The site banners failed to fetch: ${bannerResult.message}`);
    return undefined;
  }

  // Explicitly cast bannerResult.data to SiteBannerDto[]
  const dtos = bannerResult.data;
  const nowEST = new Date(
    new Date().toLocaleString('en-US', {timeZone: 'America/New_York'})
  );

  // Loop through banners in order and return the first that is active
  for (const dto of dtos) {
    const banner = transformSiteBanner(dto, archive);
    if (banner && nowEST >= banner.start && nowEST <= banner.end) {
      return banner;
    }
  }

  return undefined;
}
