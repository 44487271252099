import React from 'react';

/**
 * Renders a Facebook logo
 */
export function FacebookIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="100 100 500 500">
      <path
        id="Initiator"
        className="st1"
        d="M600,350c0-138.1-111.9-250-250-250s-250,111.9-250,250,80.7,215.6,189.6,242.6v-166.2h-51.6v-76.4h51.6v-32.9c0-85.1,38.5-124.5,122-124.5s43.2,3.1,54.3,6.2v69.3c-5.9-.6-16.1-.9-28.9-.9-41,0-56.8,15.5-56.8,55.9v27h81.7l-14,76.4h-67.6v171.8c123.8-15,219.7-120.4,219.7-248.2Z"
      />
      <path
        id="F"
        fill="transparent"
        d="M447.9,426.4l14-76.4h-81.7v-27c0-40.4,15.8-55.9,56.8-55.9s23,.3,28.9.9v-69.3c-11.2-3.1-38.5-6.2-54.3-6.2-83.5,0-122,39.4-122,124.5v32.9h-51.6v76.4h51.6v166.2c19.3,4.8,39.6,7.4,60.4,7.4s20.4-.6,30.3-1.8v-171.8h67.6Z"
      />
    </svg>
  );
}
