import getConfig from 'next/config';
import React from 'react';
import {Config, sanitize} from 'isomorphic-dompurify';
import {isObject} from '@/lib/utils/validation';

type SafeCustomHtmlProps = Readonly<{
  html: string;
  config?: Config;
}>;

/** The requirements for DomPurify's santitize to return a type of string */
type ReturnsStringConfig = {RETURN_DOM_FRAGMENT: false; RETURN_DOM: false};

/**
 * Sanitizes HTML to the best of its abilities
 * @param html The HTML to sanitize
 * @returns Sanitized HTML as a string
 */
function sanitizeHtml(html: string, config: Config | undefined) {
  if (typeof html !== 'string') return '';

  const requiredConfig: ReturnsStringConfig = {
    RETURN_DOM_FRAGMENT: false,
    RETURN_DOM: false,
  };

  const combinedConfig = isObject(config)
    ? {...config, ...requiredConfig}
    : requiredConfig;

  const sanitizedHtml = sanitize(html, combinedConfig);
  return sanitizedHtml;
}

/**
 * Renders custom HTML after ensuring that it is sanitized to the best of our abilities.
 * This function contains a fail-safe for disabling custom HTML rendering with the FLAG_ENABLE_CUSTOM_HTML
 * @param html Potentially unsafe HTML that will be sanitized before rendering
 */
export function SafeCustomHtml(props: SafeCustomHtmlProps) {
  const config = getConfig();
  const isCustomHtmlEnabled =
    config?.publicRuntimeConfig?.flagEnableCustomHtml ?? false;
  if (!isCustomHtmlEnabled) return null;

  const sanitizedHtml = sanitizeHtml(props.html, props.config);
  return <div dangerouslySetInnerHTML={{__html: sanitizedHtml}} />;
}

export const __testing__ = {sanitizeHtml};
