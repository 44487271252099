import {Result} from '@/lib/utils/result';
import {ZodString, z} from 'zod';
import {ValidationRule} from '.';
import {parseScalar} from '../parse';
import {LiteralValidationRule} from './literal';

/** A rule for validating and transforming a string value */
export class StringValidationRule<Output = string> extends ValidationRule<
  Output,
  string
> {
  _schema: ZodString;
  _default: '';

  constructor(property: string) {
    super(property);
    this._schema = z.string();
  }

  /**
   * Adds a rule requiring that the validated value must equal the given value
   * Equivalent to `.literal(property?, value)`
   * @param value - The value to validate with
   */
  equals<NewOutput extends z.Primitive>(value: NewOutput) {
    if (typeof value !== 'string')
      throw new Error(
        "Error compiling validation rules. '.string().equals()' must take a string value"
      );
    const literalRule = new LiteralValidationRule<NoInfer<NewOutput>>(
      this._property,
      value
    );
    this._replaceWith(literalRule as unknown as ValidationRule<Output, string>);
    return literalRule;
  }

  _safeParse(data: unknown): Result<Output> {
    return parseScalar(data, this._property, this._schema);
  }
}
