import {
  HierarchalNavigationDescriptor,
  LinkNavigationDescriptor,
  NavigationDescriptor,
  NavigationBehavior,
} from '@/lib/types/components/navigation';
import {isObject} from '@/lib/utils/validation';
import Link from 'next/link';
import React from 'react';

type NavigationDropdownListProps = Readonly<{
  parentDescriptor: HierarchalNavigationDescriptor;
}>;

/**
 * Renders multiple navigation links when clicked as a dropdown
 * @param navigation - The navigation information
 */
export function NavbarDropdown(props: NavigationDropdownListProps) {
  const navbarId = `navbar-${props.parentDescriptor.key}-dropdown`;
  const navigationItems = Array.isArray(props.parentDescriptor.navigationItems)
    ? props.parentDescriptor.navigationItems
    : [];

  const linkItems = navigationItems
    .filter(isObject)
    .map((navigationDescriptor: NavigationDescriptor) => (
      <li key={navigationDescriptor.key}>
        <NavigationDropdownItem navigationDescriptor={navigationDescriptor} />
      </li>
    ));

  return (
    <>
      <button
        className="btn nav-link dropdown-toggle"
        id={navbarId}
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {props.parentDescriptor.children}
      </button>
      <ul
        className="dropdown-menu dropdown-menu-end"
        aria-labelledby={navbarId}
      >
        {linkItems}
      </ul>
    </>
  );
}

/**
 * Renders an item of a navigation dropdown
 * @param navigationDescriptor - The navigation descriptor to render
 */
function NavigationDropdownItem(props: {
  readonly navigationDescriptor: NavigationDescriptor;
}) {
  switch (props.navigationDescriptor.behavior) {
    case NavigationBehavior.Hierarchal:
      return <NavbarDropdown parentDescriptor={props.navigationDescriptor} />;
    case NavigationBehavior.Link:
    case NavigationBehavior.External:
      return (
        <NavigationDropdownLink
          navigationDescriptor={props.navigationDescriptor}
        />
      );
  }
}

/**
 * Creates a link within the dropdown
 * @param navigationDescriptor - The navigationDescriptor describing this link
 */
function NavigationDropdownLink(props: {
  readonly navigationDescriptor: LinkNavigationDescriptor;
}) {
  return (
    <Link className="dropdown-item" href={props.navigationDescriptor.link}>
      {props.navigationDescriptor.children}
    </Link>
  );
}
