import React from 'react';
import Link from 'next/link';
import {Img} from '../Image';
import {ImageMedia} from '@/lib/types/media';
import {LinkNavigationDescriptor} from '@/lib/types/components/navigation';
import {isObject} from '@/lib/utils/validation';

type NavigationLogoProps = Readonly<{
  logo: ImageMedia;
  link: LinkNavigationDescriptor | string;
}>;

/**
 * Renders the logo and home link for an archive
 * @param homeNavigation - The navigation object describing the home page
 */
export function NavbarLogo(props: NavigationLogoProps) {
  const href = coerceLinkToHref(props.link);
  return (
    <Link className="department-logo nav-link" href={href}>
      <Img
        {...props.logo}
        className="d-inline-block align-top"
        loading="eager"
      />
    </Link>
  );
}

/**
 * Coerces a link navigation object into an href, or returns the given href string. If
 * neither case is valid, returns a default href
 * @param link - Either an href string, or a LinkNavigationDescriptor object containing one.
 * @returns An href string
 */
function coerceLinkToHref(link: LinkNavigationDescriptor | string) {
  const defaultHref = '#';
  if (typeof link === 'string') return link;
  if (!isObject(link)) return defaultHref;
  return link.link ?? defaultHref;
}
