import {WordpressMediaPageDto} from '@/features/archiveAsAProduct/types/dto/media/page';
import {
  WordpressImageMedia,
  WordpressMedia,
} from '@/features/archiveAsAProduct/types/media';
import {isObject} from '@/lib/utils/validation';

/**
 * Transforms a WordpressMediaPage data transfer object into a locally usable media object
 * @param dto - The Media page data transfer object to transform
 * @returns A WordpressMedia object on success, or undefined on failure
 */
export function transformWordpressMediaPage(
  dto: WordpressMediaPageDto
): WordpressMedia | undefined {
  if (!isObject(dto)) return undefined;

  switch (dto.media_type) {
    case 'image':
      return transformWordpressImagePage(dto);
    default:
      return undefined;
  }
}

/**
 * Transforms a Wordpress Image Page Data Transfer Object into
 * a common ImageMedia object
 * @param dto - The image DTO to transform
 * @returns An ImageMedia object on success. Undefined on failure.
 */
function transformWordpressImagePage(
  dto: WordpressMediaPageDto
): WordpressImageMedia | undefined {
  let height = dto.media_details?.height;
  let width = dto.media_details?.width;

  const isSourceValid = typeof dto.source_url === 'string' && !!dto.source_url;
  // Checking to see if incoming image is an SVG so we can add a default dimensions
  const isSvg = dto.mime_type === 'image/svg+xml';

  // Default dimensions for SVGs
  const defaultSVGHeight = 64;
  const defaultSVGWidth = 100;

  if (isSvg && !dto.media_details?.height && !dto.media_details?.width) {
    height = defaultSVGHeight;
    width = defaultSVGWidth;
  }

  const isHeightValid = typeof height === 'number' && height > 0;
  const isWidthValid = typeof width === 'number' && width > 0;
  if (!isSourceValid || !isHeightValid || !isWidthValid) return undefined;

  const imageMedia: WordpressImageMedia = {
    __metadata: {
      source: 'wordpress',
      id: dto.id ?? 0,
      title: dto.title?.rendered ?? '',
      mimetype: dto.mime_type ?? '',
    },
    type: 'image',
    src: dto.source_url,
    alt: dto.alt_text ?? '',
    height,
    width,
  };

  return imageMedia;
}
