import {LinkNavigationDescriptor} from '@/lib/types/components/navigation';
import {ImageMedia} from '@/lib/types/media';
import React from 'react';
import {NavbarLogo} from './NavbarLogo';

type NavbarProps = Readonly<{
  children: React.ReactNode;
  collapseBelow?: 'sm' | 'md' | 'lg' | 'xl';
  logoLink?: LinkNavigationDescriptor | string;
  mobileLogo?: ImageMedia;
}>;

/**
 * Renders a standard Navbar that is capable of collapsing into a mobile-friendly view
 * @param children - The children to render within the navbar
 */
export function Navbar(props: NavbarProps) {
  const navbarExpandClass = determineNavbarExpandClass(props.collapseBelow);

  return (
    <nav
      className={`navbar ${navbarExpandClass}`}
      id="mosaic-nav"
      aria-label="Main Navigation"
    >
      <NavbarLogo logo={props.mobileLogo} link={props.logoLink} />
      <ToggleNavbarButton />
      <div
        className="offcanvas offcanvas-end"
        id="offcanvasNavbar2"
        tabIndex={-1}
      >
        <OffCanvasNavigation />
        <div className="offcanvas-body">{props.children}</div>
      </div>
    </nav>
  );
}

/**
 * Creates a button that toggles the mobile navbar utilizing Bootstrap functionality
 */
function ToggleNavbarButton() {
  return (
    <button
      className="navbar-toggler"
      data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasNavbar2"
      type="button"
      aria-controls="offcanvasNavbar2"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span className="navbar-toggler-icon"></span>
    </button>
  );
}

/**
 * Creates a button to close the mobile navbar utilizing Bootstrap functionality
 */
function OffCanvasNavigation() {
  return (
    <div className="offcanvas-header justify-content-end">
      <button
        className="btn-close btn-close-white"
        data-bs-dismiss="offcanvas"
        type="button"
        aria-label="Close"
      />
    </div>
  );
}

function determineNavbarExpandClass(collapseBelow: string | undefined) {
  if (typeof collapseBelow !== 'string') return undefined;

  const allowedSizes = ['sm', 'md', 'lg', 'xl'];
  if (!allowedSizes.includes(collapseBelow)) return undefined;

  return `navbar-expand-${collapseBelow}`;
}
