import React from 'react';
import {ImageMedia} from '@/lib/types/media';
import {WordpressLink} from '@/features/archiveAsAProduct';

type FooterLogoProps = {
  logo: ImageMedia;
  className?: string;
  link?: WordpressLink;
  logoHeight?: number;
};

export function FooterLogo({
  logo,
  className = '',
  link,
  logoHeight,
}: FooterLogoProps) {
  // Ensure src is a string, regardless of type
  const src = typeof logo.src === 'string' ? logo.src : logo.src.src;

  const logoElement = (
    <img
      src={src}
      alt={logo.alt || 'Logo'}
      height={logoHeight || 40}
      className={className}
    />
  );

  // Only wrap in a link if a valid link object is provided
  return link && link.href ? (
    <a
      href={link.href}
      target={link.target || '_self'}
      rel={link.target === '_blank' ? 'noopener noreferrer' : undefined}
    >
      {logoElement}
    </a>
  ) : (
    logoElement
  );
}
