import {WordpressMedia} from '@/features/archiveAsAProduct/types/media';
import {isObject} from '@/lib/utils/validation';
import {WordpressMediaContentDto} from '@/features/archiveAsAProduct/types/dto/media/content';
import {WordpressMediaPageDto} from '@/features/archiveAsAProduct/types/dto/media/page';
import {transformWordpressMediaPage} from './page';
import {transformWordpressMediaContent} from './content';

/**
 * Transforms a media data transfer object into a standard WordpressMedia object
 * @param dto - The data transfer object describing a piece of media
 * @returns A WordpressMedia object
 */
export function transformWordpressMedia(
  dto: WordpressMediaContentDto | WordpressMediaPageDto | undefined
): WordpressMedia | undefined {
  if (!isObject(dto)) return undefined;

  if (isWordpressMediaPage(dto)) {
    return transformWordpressMediaPage(dto as WordpressMediaPageDto);
  }
  return transformWordpressMediaContent(dto as WordpressMediaContentDto);
}

/**
 * Checks if the given value is a WordpressMediaPageDto
 * @param possibleDto - The unknown value to determine if this is a media page
 * @returns True if a WordpressMediaPage. False otherwise.
 */
function isWordpressMediaPage(possibleDto: unknown) {
  if (!isObject(possibleDto)) return false;

  const objectDto = possibleDto as Record<string, unknown>;
  if (objectDto.template === undefined) return false;
  if (objectDto.media_type === undefined) return false;
  return true;
}

export const __testing__ = {
  isWordpressMediaPage,
};
