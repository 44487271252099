import {ArchivePost} from '@/lib/types/archivePost';
import {v} from '../validation';
import {WordpressPageDto} from '../../types/dto/page';
import {Result} from '@/lib/utils/result';

const ARCHIVE_POST_VALIDATION = v.object<ArchivePost>({
  id: v.number('id').required(),
  slug: v.string('slug'),
  title: v.string('acf.seo.title_tag'),
  content: v.string('acf.find_data_content.page_content'),
  featuredMediaId: v.number('featured_media').optional(),
  createdDate: v
    .string<Date>('date')
    .transform((date: string) => new Date(date))
    .optional(),
  categories: v.array('categories', v.number()),
});

export function transformArchivePosts(
  archivePosts: WordpressPageDto[]
): Result<ArchivePost[]> {
  const validator = v.array(ARCHIVE_POST_VALIDATION);
  return validator.safeParse(archivePosts);
}
