import {Result} from '@/lib/utils/result';
import {WordpressPageDto} from '../../types/dto/page';
import {NewsPost, WordpressNewsPage} from '../../types/news';
import {v} from '../validation';

const NEWS_POST_VALIDATION = v.object<NewsPost>({
  id: v.number('id').required(),
  slug: v.string('slug'),
  title: v.string('acf.seo.title_tag'),
  content: v.string('acf.page_content'),
  featuredMediaId: v.number('featured_media').optional(),
  createdDate: v
    .string<Date>('date')
    .transform((date: string) => new Date(date))
    .optional(),
  categories: v.array('categories', v.number()),
  newsCategories: v.array('news-categories', v.number()),
});

export function transformNewsPosts(newsDtos: WordpressPageDto[]): NewsPost[] {
  const newsPosts = v.array(NEWS_POST_VALIDATION).safeParse(newsDtos);
  if (newsPosts.ok === false) return [];
  return newsPosts.data;
}

const NEWS_PAGE_VALIDATION = v.object<WordpressNewsPage, WordpressPageDto>({
  id: v.number('id').required(),
  slug: v.string('slug').required(),
  title: v.string('title.rendered').required(),
  featuredMediaId: v.number('featured_media').optional(),
  content: v.string('acf.page_content'),
  date: v.string('date').required(),
  authorId: v.number('author').required(),
  categoryIds: v.array('news-categories', v.number().required()),
});

export function transformNewsPage(
  dto: WordpressPageDto
): Result<WordpressNewsPage> {
  return NEWS_PAGE_VALIDATION.safeParse(dto);
}
