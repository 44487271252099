import {
  SocialMediaLink,
  SocialMediaLinks,
} from '@/lib/types/components/socialMedia';
import React from 'react';
import {FacebookIcon} from '../icons/FacebookIcon';
import {InstagramIcon} from '../icons/InstagramIcon';
import {TwitterIcon} from '../icons/TwitterIcon';
import {LinkedInIcon} from '../icons/LinkedInIcon';
import {YouTubeIcon} from '../icons/YouTubeIcon';
import {BlueskyIcon} from '../icons/BlueskyIcon';

type FooterSocialMediaProps = Readonly<{
  socialMediaLinks: SocialMediaLinks;
}>;

/**
 * Renders social media links within a footer. If social media links are not provided,
 * a default set of social media links will be used instead.
 * @param socialMediaLinks - Optional. An object containing the social media links to render
 */
export function FooterSocialMedia(props: FooterSocialMediaProps) {
  const links = props.socialMediaLinks;

  return (
    <ul className="footer-social" data-testid="footer-social">
      <FooterSocialMediaLink {...links.facebook} icon={<FacebookIcon />} />
      <FooterSocialMediaLink {...links.instagram} icon={<InstagramIcon />} />
      <FooterSocialMediaLink {...links.twitter} icon={<TwitterIcon />} />
      <FooterSocialMediaLink {...links.linkedIn} icon={<LinkedInIcon />} />
      <FooterSocialMediaLink {...links.youtube} icon={<YouTubeIcon />} />
      <FooterSocialMediaLink {...links.bluesky} icon={<BlueskyIcon />} />
    </ul>
  );
}

type SocialMediaLinkProps = Readonly<
  {
    icon: React.ReactNode;
  } & SocialMediaLink
>;

/**
 * Renders a social media link for use wihtin the footer
 * @param icon - The React component icon for this component
 * @param href - The target of the link
 * @param screenReaderText - The hidden text descibing the link for screenreaders
 */
function FooterSocialMediaLink(props: SocialMediaLinkProps) {
  if (!props.href) return null;

  return (
    <li data-testid="social-media-link-li">
      <a href={props.href}>
        {props.icon}
        <p className="visually-hidden">{props.screenReaderText}</p>
      </a>
    </li>
  );
}

export const __testing__ = {
  FooterSocialMediaLink,
};
