import React from 'react';
import {NavbarItem} from './NavbarItem';
import {NavigationDescriptor} from '@/lib/types/components/navigation';
import {isObject} from '@/lib/utils/validation';

type NavigationSectionProps = Readonly<{
  navigation: NavigationDescriptor[];
  children?: React.ReactNode;
}>;

/**
 * Renders a navigation section containing navigation items
 * @param navigation - The list of navigation items to render within this section
 * @param children - Optional. Any additional children to render to the right of the nav links
 */
export function NavbarNavigation(props: NavigationSectionProps) {
  const navigation = Array.isArray(props.navigation) ? props.navigation : [];
  const navLinks = navigation
    .filter(isObject)
    .map((navigation: NavigationDescriptor) => (
      <NavbarItem key={navigation.key} navigationDescriptor={navigation} />
    ));

  return (
    <ul className="navbar-nav" data-testid="navigation-section">
      {navLinks}
      {props.children}
    </ul>
  );
}
