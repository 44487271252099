import {Category, CategoryTaxonomy} from '../../types/category';
import {CategoryDto} from '../../types/dto/category';
import {v} from '../validation';

const CATEGORY_VALIDATION = v.object<Category, CategoryDto>({
  id: v.number('id'),
  name: v.string('name').required(),
  slug: v.string('slug').required(),
  taxonomy: v.string<CategoryTaxonomy>('taxonomy').required(),
});

export function transformCategories(categoryDtos: CategoryDto[]) {
  const validator = v.array(CATEGORY_VALIDATION);
  return validator.safeParse(categoryDtos);
}
