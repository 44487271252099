import Link from 'next/link';
import React from 'react';
import {NavbarDropdown} from './NavbarDropdown';
import {
  HierarchalNavigationDescriptor,
  LinkNavigationDescriptor,
  NavigationDescriptor,
  NavigationBehavior,
} from '@/lib/types/components/navigation';

type NavbarItemProps = Readonly<{
  navigationDescriptor: NavigationDescriptor;
  listItemClasses?: string;
}>;

const listItemClasses = 'nav-item';

/**
 * Renders a single navigation link. If the navigation is hierarchal,
 * a NavItem with an interior list is rendered instead
 * @param navigation - The navigation item to render
 */
export function NavbarItem(props: NavbarItemProps) {
  switch (props.navigationDescriptor.behavior) {
    case NavigationBehavior.Hierarchal:
      return (
        <HierarchalNavigation
          navigationDescriptor={props.navigationDescriptor}
        />
      );
    case NavigationBehavior.Link:
    case NavigationBehavior.External:
      return (
        <LinkNavigation navigationDescriptor={props.navigationDescriptor} />
      );
  }

  return null;
}

/**
 * Renders a link within the navigation bar
 * @param navigationDescriptor - The navigationDescriptor describing this link
 */
function LinkNavigation(props: {
  readonly navigationDescriptor: LinkNavigationDescriptor;
}) {
  return (
    <li className={listItemClasses}>
      <Link
        className="nav-link"
        href={props.navigationDescriptor.link}
        target={props.navigationDescriptor.target}
        rel={props.navigationDescriptor.rel}
      >
        {props.navigationDescriptor.children}
      </Link>
    </li>
  );
}

/**
 * Renders a button that opens a dropdown within the navigation bar
 * @param navigationDescriptor - The navigationDescriptor describing this link
 */
function HierarchalNavigation(props: {
  readonly navigationDescriptor: HierarchalNavigationDescriptor;
}) {
  return (
    <li className={listItemClasses}>
      <NavbarDropdown parentDescriptor={props.navigationDescriptor} />
    </li>
  );
}
