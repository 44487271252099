import {WordpressFooterDto} from '@/features/archiveAsAProduct/types/dto/footer';
import {transformWordpressMedia} from './media';
import {WordpressLinkDto} from '../../types/dto/link';
import {
  WordpressFooter,
  WordpressLink,
} from '@/features/archiveAsAProduct/types/footer';
import {SocialMediaLinks} from '@/lib/types/components/socialMedia';
import {isObject} from '@/lib/utils/validation';

/**
 * Transforms a data transfer object containing a link into a locally usable object
 * @param dto - The WordpressLinkDto to transform
 * @param archive - The archive slug for building local links
 * @returns The transformed link
 */
export function transformUnknownWordpressLink(
  dto: WordpressLinkDto,
  archive: string
): WordpressLink | undefined {
  if (!isObject(dto) || !dto.url) {
    return undefined;
  }

  return {
    title: dto.title,
    href: dto.url,
    target: dto.target,
  };
}

/**
 * Transforms the raw data transfer object into a locally-usable object
 * @param dto - The WordpressFooterDto to transform
 * @returns A transformed WordpressFooter object
 */
export function transformWordpressFooter(
  dto: WordpressFooterDto,
  archive: string
): WordpressFooter {
  if (!isObject(dto)) dto = {} as WordpressFooterDto;

  const additionalLogos = Array.isArray(dto.funder_logos)
    ? dto.funder_logos.map(additionalLogoDto => {
        const link = shouldUseLink(additionalLogoDto.link_to_funder)
          ? transformUnknownWordpressLink(
              additionalLogoDto.link_to_funder,
              archive
            )
          : null;
        const logo_height = additionalLogoDto.logo_height;

        return {
          logo: transformWordpressMedia(additionalLogoDto.funder_image),
          link: link,
          logo_height: logo_height,
        };
      })
    : [];

  const footer: WordpressFooter = {
    logo: additionalLogos[0]?.logo,
    icpsrLogo: transformWordpressMedia(dto.icpsr_university_of_michigan_logo),
    socialMediaLinks: transformSocialMedia(dto.social_media_links),
    newsletter: transformUnknownWordpressLink(dto.newsletter_link, archive),
    accessibility: transformUnknownWordpressLink(
      dto.accessibility_link,
      archive
    ),
    privacyPolicy: transformUnknownWordpressLink(dto.privacy_link, archive),
    additionalLinks: Array.isArray(dto.links)
      ? dto.links.map(linkDto =>
          transformUnknownWordpressLink(linkDto.link, archive)
        )
      : [],
    additionalLogos,
    textBlurb: dto.text_blurb || '',
  };

  return footer;
}

/**
 * Determines if a link should be used based on its content
 * @param linkDto - The WordpressLinkDto to evaluate
 * @returns boolean - Whether the link has valid content
 */
function shouldUseLink(linkDto: WordpressLinkDto): boolean {
  return !!linkDto && linkDto.url !== '';
}

/**
 * Transforms the social media from the data transfer object into a locally usable object
 * @param dto - The social media portion of the WordpressFooterDto
 * @returns A standard SocialMediaLinks object
 */
function transformSocialMedia(
  dto: WordpressFooterDto['social_media_links']
): SocialMediaLinks {
  if (!isObject(dto)) return {};

  return {
    facebook: transformSocialMediaLink(dto?.facebook_link),
    instagram: transformSocialMediaLink(dto?.instagram_link),
    twitter: transformSocialMediaLink(dto?.x__twitter_link),
    linkedIn: transformSocialMediaLink(dto?.linkedin_link),
    youtube: transformSocialMediaLink(dto?.youtube_link),
    bluesky: transformSocialMediaLink(dto?.bluesky_link),
  };
}

/**
 * Transforms a data transfer object containing a social media link into a locally-usable type
 * @param dto - The WordpressLinkDto to transform
 * @returns A standard SocialMediaLink, if valid. Otherwise, undefined
 */
function transformSocialMediaLink(dto: WordpressLinkDto) {
  if (!isObject(dto) || !dto.url) return undefined;
  return {
    screenReaderText: dto.title ?? '',
    href: dto.url,
  };
}

// Export the testing utilities
export const __testing__ = {
  transformSocialMedia,
  transformSocialMediaLink,
};
