import {Result, OkResult, ok} from '@/lib/utils/result';
import {ZodArray, z} from 'zod';
import {ValidationRule} from '.';
import {zodParseToResult} from '@/features/archiveAsAProduct/lib/zod';
import {getArrayArguments} from '../arguments';
import {getRequestedRuleData} from '../parse';

/** A rule for validating and transforming data within an array */
export class ArrayValidationRule<Output, Input = Output> extends ValidationRule<
  Output[],
  Input[]
> {
  _content: ValidationRule<Output>;
  _schema: ZodArray<z.ZodType<Output, z.ZodTypeDef, Output>, 'many'>;

  constructor(property: string, content: ValidationRule<Output>);
  constructor(content: ValidationRule<Output>);
  constructor(
    arg1: string | ValidationRule<Output>,
    arg2?: ValidationRule<Output>
  ) {
    const {property, content} = getArrayArguments(arg1, arg2);

    super(property);
    this._content = content;
    this._schema = z.array(this._content.getZod());
  }

  _safeParse(data: unknown): Result<Output[]> {
    const targetData = getRequestedRuleData(data, this._property);

    if (!Array.isArray(targetData)) {
      const zodResult = this._schema.safeParse(data);
      return zodParseToResult(zodResult);
    }

    const validatedData = targetData
      .map<Result<Output>>(item => this._content.safeParse(item))
      .filter<OkResult<Output>>(
        (result: Result<Output>): result is OkResult<Output> =>
          result.ok === true
      )
      .map<Output>((result: OkResult<Output>) => result.data);

    return ok(validatedData);
  }
}
