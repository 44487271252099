/** Describes an item to be rendered within a navigation bar and its behavior */
export type NavigationDescriptor =
  | LinkNavigationDescriptor
  | HierarchalNavigationDescriptor;

/** A navigation item that leads to a different page, either internally or externally */
export type LinkNavigationDescriptor = {
  behavior: NavigationBehavior.Link | NavigationBehavior.External;
  /** A link that leads to a new page, either internal or external */
  link: string;
  /** The relationship between the current document and the target document. See more: {@link https://www.w3schools.com/tags/att_a_rel.asp} */
  rel?: string;
  /** Where to open the specified document. See more: {@link https://www.w3schools.com/tags/att_a_target.asp} */
  target?: string;
} & CommonNavigationDescriptor;

/** A navigation item that contains a dropdown with further navigation items */
export type HierarchalNavigationDescriptor = {
  behavior: NavigationBehavior.Hierarchal;
  navigationItems: NavigationDescriptor[];
} & CommonNavigationDescriptor;

/** Common parts of all navigation items */
type CommonNavigationDescriptor = {
  /** The unique key for use when rendering in loops */
  key: string;
  /** Describes what to render within the link itself. Typically text */
  children: React.ReactNode;
};

/** Identifies the different behaviors of items within the navigation */
export enum NavigationBehavior {
  /** This navigation link leads to an internal page */
  Link,
  /** This navigation link leads to an external website */
  External,
  /** This link triggers a dropdown to display more links */
  Hierarchal,
}
