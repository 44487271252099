import {ValidationRule} from './rules';
import {ObjectChildRules} from './rules/object';

/**
 * Gets the arguments for creating an array validation rule
 * @param arg1 - The first argument given for creating an array validation rule
 * @param arg2 - The second, optional argument given for creating an array validation rule
 * @returns An object containing the property (if present) and content
 */
export function getArrayArguments<Output>(
  arg1: string | ValidationRule<Output>,
  arg2?: ValidationRule<Output>
) {
  let property: string | undefined = undefined;
  let content: ValidationRule<Output> | undefined = undefined;
  if (typeof arg1 === 'string') {
    property = arg1;
    content = arg2;
  } else if (arg1 instanceof ValidationRule) {
    content = arg1;
  }

  return {property, content};
}

/**
 * Gets the arguments for creating an object validation rule
 * @param arg1 - The first argument given for creating an object validation rule
 * @param arg2 - The second, optional argument given for creating an object validation rule
 * @returns An object containing the property (if present) and content
 */
export function getObjectArguments<Output, Input>(
  arg1: string | ObjectChildRules<Output>,
  arg2?: ObjectChildRules<Output>
) {
  let property: string | undefined = undefined;
  let content: ObjectChildRules<Output> | undefined = undefined;
  if (typeof arg1 === 'string') {
    property = arg1;
    content = arg2;
  } else {
    content = arg1;
  }

  return {property, content};
}
