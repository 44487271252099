import React from 'react';
import {useRouter} from 'next/router';
import {useSiteBanner} from '@/lib/hooks/useSiteBanner';
import {PlainHtml} from '@/features/archiveAsAProduct/components/fields/PlainHtml';

export function AnnouncementBanner() {
  const router = useRouter();
  const pathSegments = router.asPath.split('/');
  const archive = pathSegments[1] || '';

  const {banner, loading, error} = useSiteBanner(archive);

  if (loading || error || !banner) return null;

  return (
    <aside className={`site-banner site-banner-${banner.color}`}>
      <div className="px-3 stack stack-2">
        <header>{banner.headerText}</header>
        <div className="site-banner-body">
          <PlainHtml content={banner.content} />
        </div>
      </div>
    </aside>
  );
}
