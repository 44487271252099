import {Navbar} from '@/components/common/navbar/Navbar';
import {NavbarLogo} from '@/components/common/navbar/NavbarLogo';
import {NavbarNavigation} from '@/components/common/navbar/NavbarNavigation';
import {SignInUser} from '@/components/common/SignInUser';
import {NavigationDescriptor} from '@/lib/types/components/navigation';
import {WordpressNavigation} from '@/features/archiveAsAProduct';
import {isObject} from '@/lib/utils/validation';
import React from 'react';

type ArchiveHeaderProps = Readonly<{
  lowerNavigation?: NavigationDescriptor[];
  upperNavigation?: NavigationDescriptor[];
  branding?: WordpressNavigation['branding'];
}>;

/**
 * Renders a shared header for the different archives. If arguments are missing, defaults are used instead
 * @param upperNavigation - Optional. The nagivation items for the global (upper) bar
 * @param lowerNavigation - Optional. The nagivation items for the local (lower) bar
 * @param branding - Optional. The logo and home link for this archive.
 */
export function ArchiveHeader(props: ArchiveHeaderProps) {
  const lowerNavigation = Array.isArray(props.lowerNavigation)
    ? props.lowerNavigation
    : LOCAL_NAVIGATION;

  const upperNavigation = Array.isArray(props.upperNavigation)
    ? props.upperNavigation
    : GLOBAL_NAVIGATION;

  const branding = isObject(props.branding) ? props.branding : defaultBranding;

  return (
    <header>
      <div id="skip">
        <a href="#skipto">Skip to Main Content</a>
      </div>

      <Navbar
        mobileLogo={branding.mobileLogo}
        collapseBelow="lg"
        logoLink={branding.link}
      >
        <div className="nav-bg-light">
          <NavbarLogo logo={branding.desktopLogo} link={branding.link} />
          <NavbarNavigation navigation={lowerNavigation} />
        </div>

        <div className="nav-bg-primary navbar-dark">
          <NavbarNavigation navigation={upperNavigation}>
            <SignInUser />
          </NavbarNavigation>
        </div>
      </Navbar>
    </header>
  );
}

const LOCAL_NAVIGATION: NavigationDescriptor[] = [
  {
    key: 'find-data',
    children: 'Find data',
    behavior: 2,
    navigationItems: [
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesicpsrindexhtml',
        children: 'Find data',
        link: 'https://www.icpsr.umich.edu/web/pages/ICPSR/index.html',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesicpsrssvd',
        children: 'Share/compare variables',
        link: 'https://www.icpsr.umich.edu/web/pages/ICPSR/ssvd/',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesicpsrcitations',
        children: 'Data-related publications',
        link: 'https://www.icpsr.umich.edu/web/pages/ICPSR/citations/',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesicpsricpsrhtml',
        children: 'Thematic data collections',
        link: 'https://www.icpsr.umich.edu/web/pages/ICPSR/icpsr.html',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebaboutcms5078',
        children: 'Help and user guide',
        link: 'https://www.icpsr.umich.edu/web/about/cms/5078',
      },
    ],
  },
  {
    key: 'share-data',
    children: 'Share data',
    behavior: 2,
    navigationItems: [
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesdepositindexhtml',
        children: 'Start deposit',
        link: 'https://www.icpsr.umich.edu/web/pages/deposit/index.html',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesdepositguideindexhtml',
        children: 'Data preperation guide',
        link: 'https://www.icpsr.umich.edu/web/pages/deposit/guide/index.html',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesdepositconfidentialityhtml',
        children: 'Confidentiality',
        link: 'https://www.icpsr.umich.edu/web/pages/deposit/confidentiality.html',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebdepositcms4254',
        children: 'Share NIH data',
        link: 'https://www.icpsr.umich.edu/web/deposit/cms/4254',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesdepositsuggesthtml',
        children: 'Suggest data to archive',
        link: 'https://www.icpsr.umich.edu/web/pages/deposit/suggest.html',
      },
    ],
  },
  {
    key: 'summer-program',
    children: 'Summer program',
    behavior: 2,
    navigationItems: [
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagessumprog',
        children: 'Home',
        link: 'https://www.icpsr.umich.edu/web/pages/sumprog/',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagessumprogabouthtml',
        children: 'Program overview',
        link: 'https://www.icpsr.umich.edu/web/pages/sumprog/about.html',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagessumprogcourseshtml',
        children: 'Courses',
        link: 'https://www.icpsr.umich.edu/web/pages/sumprog/courses.html',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagessumprogregistrationhtml',
        children: 'Registration & fees',
        link: 'https://www.icpsr.umich.edu/web/pages/sumprog/registration.html',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagessumprogscholarshipsindexhtml',
        children: 'Scholarships',
        link: 'https://www.icpsr.umich.edu/web/pages/sumprog/scholarships/index.html',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebsumprogcms4645',
        children: 'Travel and lodging',
        link: 'https://www.icpsr.umich.edu/web/sumprog/cms/4645',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagessumproggivinghtml',
        children: 'Giving',
        link: 'https://www.icpsr.umich.edu/web/pages/sumprog/giving.html',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebsumprogcms4541',
        children: 'FAQ',
        link: 'https://www.icpsr.umich.edu/web/sumprog/cms/4541',
      },
    ],
  },
  {
    key: 'teaching-learning',
    children: 'Teaching & learning',
    behavior: 2,
    navigationItems: [
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesinstructors',
        children: 'Overview',
        link: 'https://www.icpsr.umich.edu/web/pages/instructors/',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesinstructorsexerciseshtml',
        children: 'Classroom exercises',
        link: 'https://www.icpsr.umich.edu/web/pages/instructors/exercises.html',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesinstructorsstudent-resourceshtml',
        children: 'Resources for students',
        link: 'https://www.icpsr.umich.edu/web/pages/instructors/student-resources.html',
      },
    ],
  },
  {
    key: 'data-management',
    children: 'Data management',
    behavior: 2,
    navigationItems: [
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesdatamanagementindexhtml',
        children: 'Overview',
        link: 'https://www.icpsr.umich.edu/web/pages/datamanagement/index.html',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesdatamanagementqualityhtml',
        children: 'Quality',
        link: 'https://www.icpsr.umich.edu/web/pages/datamanagement/quality.html',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesdatamanagementpreservationpoliciesindexhtml',
        children: 'Preservation',
        link: 'https://www.icpsr.umich.edu/web/pages/datamanagement/preservation/policies/index.html',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesdatamanagementlifecycleaccesshtml',
        children: 'Access',
        link: 'https://www.icpsr.umich.edu/web/pages/datamanagement/lifecycle/access.html',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesdatamanagementconfidentialityindexhtml',
        children: 'Confidentiality',
        link: 'https://www.icpsr.umich.edu/web/pages/datamanagement/confidentiality/index.html',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesdatamanagementcitationshtml',
        children: 'Citations',
        link: 'https://www.icpsr.umich.edu/web/pages/datamanagement/citations.html',
      },
    ],
  },
];
const GLOBAL_NAVIGATION: NavigationDescriptor[] = [
  {
    key: 'membership',
    children: 'ICPSR Membership',
    behavior: 2,
    navigationItems: [
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesmembershipindexhtml',
        children: 'Overview',
        link: 'https://www.icpsr.umich.edu/web/pages/membership/index.html',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebmembershipadministrationinstitutions',
        children: 'Member list',
        link: 'https://www.icpsr.umich.edu/web/membership/administration/institutions',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesmembershipjoinhtml',
        children: 'How to join',
        link: 'https://www.icpsr.umich.edu/web/pages/membership/join.html',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesmembershipmanage-membershiphtml',
        children: 'Official rep tools',
        link: 'https://www.icpsr.umich.edu/web/pages/membership/manage-membership.html',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesmembershiporpromoting-icpsrhtml',
        children: 'Promoting ICPSR',
        link: 'https://www.icpsr.umich.edu/web/pages/membership/or/promoting-icpsr.html',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesmembershippublicationsindexhtml',
        children: 'News & publications',
        link: 'https://www.icpsr.umich.edu/web/pages/membership/publications/index.html',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebmembershipcms4611',
        children: 'Biennial meeting',
        link: 'https://www.icpsr.umich.edu/web/membership/cms/4611',
      },
    ],
  },
  {
    key: 'about',
    children: 'About ICPSR',
    behavior: 2,
    navigationItems: [
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesabout',
        children: 'Overview and mission',
        link: 'https://www.icpsr.umich.edu/web/pages/about/',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesaboutcontact-peoplehtml',
        children: 'People',
        link: 'https://www.icpsr.umich.edu/web/pages/about/contact-people.html',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesaboutgovernancecouncilhtml',
        children: 'Governance',
        link: 'https://www.icpsr.umich.edu/web/pages/about/governance/council.html',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesaboutdeihtml',
        children: 'Diversity, equity and inclusion statement',
        link: 'https://www.icpsr.umich.edu/web/pages/about/dei.html',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebaboutcmsnews',
        children: 'News',
        link: 'https://www.icpsr.umich.edu/web/about/cms/news',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesabouteventshtml',
        children: 'Events',
        link: 'https://www.icpsr.umich.edu/web/pages/about/events.html',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesaboutcareershtml',
        children: 'Careers',
        link: 'https://www.icpsr.umich.edu/web/pages/about/careers.html',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesabouthistory',
        children: 'History',
        link: 'https://www.icpsr.umich.edu/web/pages/about/history/',
      },
      {
        behavior: 1,
        key: 'httpswwwicpsrumicheduwebpagesicpsricpsrhtml',
        children: 'Data stewardship and research projects',
        link: 'https://www.icpsr.umich.edu/web/pages/ICPSR/icpsr.html',
      },
    ],
  },
  {
    key: 'giving',
    children: 'Giving',
    behavior: 1,
    link: 'https://www.icpsr.umich.edu/web/pages/about/donate.html',
  },
];
const defaultBranding = {
  link: {
    behavior: 1,
    key: 'httpswwwicpsrumichedu',
    children: '',
    link: 'https://www.icpsr.umich.edu/',
  },
  desktopAlt: 'ICPSR',
  mobileAlt: 'ICPSR',
  desktopLogoId: 4,
  mobileLogoId: 5,
  desktopLogo: {
    __metadata: {
      source: 'wordpress',
      id: 4,
      title: 'icpsr-logo-blue',
      mimetype: 'image/png',
    },
    type: 'image',
    src: 'https://wpvip.icpsr.umich.edu/icpsr/wp-content/uploads/sites/11/2024/05/icpsr-logo-blue.png',
    alt: 'ICPSR',
    height: 40,
    width: 225,
  },
  mobileLogo: {
    __metadata: {
      source: 'wordpress',
      id: 5,
      title: 'icpsr-logo-blue-mobile',
      mimetype: 'image/png',
    },
    type: 'image',
    src: 'https://wpvip.icpsr.umich.edu/icpsr/wp-content/uploads/sites/11/2024/05/icpsr-logo-blue-mobile.png',
    alt: 'ICPSR',
    height: 32,
    width: 180,
  },
} as WordpressNavigation['branding'];

export const __testing__ = {
  defaultLocalNavigation: LOCAL_NAVIGATION,
  defaultGlobalNavigation: GLOBAL_NAVIGATION,
  defaultBranding,
};
